import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class CommonService {

  currentTab: any;
  currentLead: any;
  currentLeadStaus: any = 10;
  filterDateRange: any;
  // socketId: BehaviorSubject<any> = new BehaviorSubject("");
  socketId: string = '';
  notificationTab: string = 'AllFreeUsersDetails';


  constructor(private datePipe: DatePipe) { }




  getTokenId(jwt: any) {
    const helper = new JwtHelperService();
    let token: any = jwt || localStorage.getItem('accessToken');
    const decoded = helper.decodeToken(token);

    return decoded;

  }





  textBreak(dataVar: any) {
    let rtn = dataVar.text.replace(/,/g, ", ");
    return rtn;
  }




  notValidField(dataVar: any) {

    let notValid = false;
    let formG = dataVar.fbGroup;
    let field = dataVar.field?.split(",");

    if (formG && field) {

      for (let key in field) {

        if (formG.get(field[key]).status == "INVALID") {
          notValid = true;
        }
      }
    }

    return notValid;
  }


  inValidLoginId(control: any): { [key: string]: any } | null {
    let regExMail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,4}$');

    if (regExMail.test(control.value) || (!Number.isNaN(control.value) && control.value.length == 10)) {
      return null;

    }
    return { 'inValidLoginId': true };

  }



  dateFormat(dtVal: any, type: any) {

    
    if (!dtVal) {
      return;
    }

    let outPut: any = [];

    if (Array.isArray(dtVal)) {
      for (let vl in dtVal) {
        let ndate = this.datePipe.transform(dtVal[vl] || "", 'yyyy-MM-dd');
        outPut.push(ndate);
      }
    }
    else {
      let dateUpdate = dtVal;


      if (typeof dateUpdate == 'string') {
        let splitDt = dtVal?.split(" ")[0];
        let dateArr = splitDt?.split("-");
        if (dateArr?.[0].length == 2) {
          dateUpdate = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
        }
      }

      outPut = this.datePipe.transform(dateUpdate || "", type || 'yyyy-MM-dd');
    }

    return outPut

  }


  clockTimer(dataVar: any) {
    let timeArr = dataVar.split(":");

    console.log(dataVar)


    return dataVar;
  }


  copyObject(target: any, source: any) {

    for (let key in target) {
      target[key] = source[key];
    }

  }



  preDate() {
    let dateVal = new Date();
    dateVal.setDate(dateVal.getDate() - 1);

    return dateVal;
  }


  findDuplicateInCart(dataVar: any) {
    let addIn: any = true;
    let delTest: any = []
    let addedTest = dataVar.cartItem;
    let cmpTest = dataVar.currentTest;
    let isHC = (dataVar.selectedHC == 'HC') ? true : false;
    let walkinTest: any = [];


    if (isHC && !cmpTest.is_hc && addedTest.length == 1) {
      walkinTest.push(cmpTest);

    }
    else {

      for (let i = 0; i < addedTest.length - 1; i++) {
        let item: any = addedTest[i];

        if (isHC && !cmpTest.is_hc && walkinTest.indexOf(cmpTest) == -1) {
          walkinTest.push(cmpTest);
        }


        if (cmpTest.test_id == item.test_id || this.testCompare({ testA: cmpTest, testB: item, cnd: 'c2p' }) || this.testCompare({ testA: cmpTest, testB: item, cnd: 'p2p' })) {
          addIn = false;
          if (walkinTest.indexOf(cmpTest) == -1) {
            delTest.push(cmpTest);
          }

          break;
        }


      }

      if (addIn) {
        for (let i = 0; i < addedTest.length - 1; i++) {
          let item: any = addedTest[i];

          if (delTest.indexOf(item) != -1 || walkinTest.indexOf(item) != -1) {
            continue;
          }

          if (this.testCompare({ testA: item, testB: cmpTest, cnd: 'c2p' }) || this.testCompare({ testA: item, testB: cmpTest, cnd: 'p2p' })) {
            delTest.push(item);

          }

        }

      }
    }

    let outData = { delItem: delTest, walkin: walkinTest };
    return outData;

  }

  testCompare(dataVar: any) {
    let blockAddIn: any = false;
    if (dataVar.cnd == 'c2p' && dataVar.testB.profileTest?.length > 0) {
      dataVar.testB.profileTest?.forEach((test: any) => {
        if (test == dataVar.testA.test_id) {
          blockAddIn = true;
        }

      });

    }
    else if (dataVar.cnd == 'p2p' && dataVar.testA.profileTest?.length > 0) {
      blockAddIn = true;
      dataVar.testA.profileTest?.forEach((test: any) => {
        if (dataVar.testB.profileTest?.findIndex((item: any) => test == item.test_id) < 0) {
          blockAddIn = false;
        }


      });

    }

    return blockAddIn;


  }

  /*jsonToBase64(object: any) {
    const json = JSON.stringify(object);
    return Buffer.from(json).toString("base64");
  }


  base64ToJson(base64String: any) {
   const json = Buffer.from(base64String, "base64").toString();
   return JSON.parse(json);
  }*/




}
