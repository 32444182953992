<div class="offcanvas offcanvas-start sideMenu" tabindex="-1" id="offcanvasDiv" aria-labelledby="offcanvasLabel">
   <div class="offcanvas-header pb-0">

     <!--<img src="assets/images/logo.png" height="32" alt="pathkind_e-dos">--> 
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" #closeSideNavBtn></button>
      
   
   </div>

   <div class="d-flex justify-content-center align-items-center flex-column gap-2"> 
      <div class="iconLogo"><img  src="assets/images/icon.png" /></div>
      <div class="text-muted">CRM</div>
   </div>
   


   <div class="offcanvas-body sideMenuList">

     
      
      <div #menuListDiv>

         <!--<div class="singleMenu" type="button" [routerLink]="['/dashboard']" routerLinkActive="activeMenu" [routerLinkActiveOptions]="{ exact: true }">Dashboard</div>-->
         
      
      
         <div class="accordion accordion-flush" id="rootNav">
            <ng-container *ngFor="let item of menuListData; let rowN = index;">
   
               <div class="accordion-item"  *ngIf="item.submenu?.length==0"> 
                  
                  <div class="singleMenu" type="button" [routerLink]="[item.module_url]" routerLinkActive="activeMenu" [routerLinkActiveOptions]="{ exact: false }"  *ngIf="item.module_url != 'lims-utility'"><i  [ngClass]="iconClass[item.module_url]"></i> {{item.module_name}}</div>

                  <a class="singleMenu text-decoration-none" type="button" [routerLink]="[item.module_url]" routerLinkActive="activeMenu" [routerLinkActiveOptions]="{ exact: false }" target="_blank" *ngIf="item.module_url == 'lims-utility'"><i  [ngClass]="iconClass[item.module_url]"></i> {{item.module_name}}</a>
                     
               </div>
            
               <div class="accordion-item"  *ngIf="item.submenu?.length>0">
                  <div class="accordion-header">
                     <div class="accordion-button collapsed" [ngClass]="{'activeMenu':isChildActive(item.submenu,item.module_name)}" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#tark'+rowN" aria-expanded="false" aria-controls="flush-collapseOne"><i  [ngClass]="iconClass[item.submenu[0].module_url]"></i> {{item.module_name}}</div>
                        
                  </div>
   
                  <div id="tark{{rowN}}" class="accordion-collapse collapse hide" data-bs-parent="#rootNav">
                     <div class="accordion-body">
                        <div class="accordion-item" *ngFor="let child of item.submenu; let childRowN = index;">
   
                           <div class="singleMenu" type="button" [routerLink]="[child.module_url]" routerLinkActive="activeMenu" [routerLinkActiveOptions]="{ exact: true }">{{child.module_name}}</div>
                                 
                        </div>
                       
                     </div>
               </div>
                  
               </div>
   
            </ng-container>
         </div>
       
        
         <div class="singleMenu logoutBtn" type="button"  (click)="logout()"><i class="bi bi-dice-1-fill" style="font-size: 18px;"></i> Logout</div>
      
      </div>


      


   </div>


</div>

