<div [ngClass]="{'innerPage': !title}">
    <div class="w-100">

        <div class="pageHeader">
            <div class="heading2 after1" [ngClass]="{'pt-3': title, 'px-2': title}">Notes <span
                    class="p-2">({{notes?.length ? notes?.length : 0}})</span>
            </div>
        </div>

        <div class="shadowSm1">
            <div class="customTabel" #fixDiv>
            </div>
            <div class="customTabel text-break">
                <div class="d-flex p-0 border-top">
                    <div class="w-25 note-sidebar custum-overflow border-end">
                        <ng-container *ngFor="let item of notes; let i = index;">
                            <!-- <a href="javascript: void(0)" class="text-decoration-none text-black"
                                [ngClass]="{'fw-bold': item.knowledge_center.is_read}">
                                <div class="text-truncate">{{item.knowledge_center.header }}</div>
                            </a> -->
                            <div [ngClass]="{'active-notes': currentIndex == i }">
                                <a href="javascript: void(0)" class="text-decoration-none text-black"
                                    (click)="getNotesMessage(item, i)">
                                    <div class="text-truncate note-item"
                                        [innerHTML]="item.knowledge_center.header | titlecase"
                                        [ngClass]="{'fw-bold': !item.knowledge_center.is_read}">
                                    </div>
                                </a>
                            </div>
                        </ng-container>
                    </div>
                    <div class="w-75 note-body p-2  custum-overflow border-end text-black">
                        <div class="text-end">
                            Published by: <span class="fw-bold">{{message?.createdBy}} {{message?.dt | date : 'dd-MM-YYYY hh:mm a'}}</span>
                        </div>
                        <div [innerHTML]="message?.header" class="pt-1"></div>
                        <div [innerHTML]="message?.body" class="py-2"></div>
                        <div class="d-flex gap-2 pl-5">
                            <!-- <div *ngFor="let item of message?.prescriptions;">
                                <img [src]="item.url" alt="" />
                                <p class="text-break p-0 m-0">{{getFileName(item.filename)}}</p>
                            </div> -->
                            <div *ngFor="let item of message?.prescriptions;">
                                <div [hidden]="!item.is_open_path"><a href="{{item.url}}" #fileLink download
                                        target="_blank"><img src="assets/images/imageIcon.png" height="50" width="50"
                                            *ngIf="item.url" /></a></div>
                                <div *ngIf="!item.is_open_path" class="cursorPointer"
                                    (click)="getFilePath(item, fileLink)"><img src="assets/images/imageIcon.png"
                                        height="50" width="50" *ngIf="item.url" /></div>
                                <!-- <a href="{{item.url}}" #fileLink download target="_blank">
                                    <div class="cursorPointer" (click)="getFilePath(item, fileLink)">
                                        <img src="assets/images/imageIcon.png" height="50" width="50" />
                                    </div>
                                </a> -->
                                <p class="text-break p-0 m-0">{{getFileName(item.filename)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>