import { animate, animation, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WebapiService } from 'src/app/services/webapi.service';
import { rootEnv } from 'src/environments/config';
import { AddLeadComponent } from 'src/app/modules/lead-management/add-lead/add-lead.component';
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [trigger('animation', [
    transition(':enter', [
      style({ height: '0px', 'padding-top': '0', 'padding-bottom': '0' }),  // initial
      animate('0.5s',
        style({ height: '*', 'padding-top': '*', 'padding-bottom': '*' }))  // final
    ]),
    transition(':leave', [
      style({ height: '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 }),  // initial
      animate('0.5s',
        style({ height: '0px', 'padding-top': '0', 'padding-bottom': '0', opacity: 0 }))  // final
    ])
  ])]
})
export class FooterComponent implements OnInit {

  currentUrl: String | undefined;
  todayDate: any = new Date();
  appVersion: any;
  userDetail: any;
  isShowLeadpage: boolean = true;
  limsUrl:any;
  is_ameyoEnabledSession : any;

  constructor(private webApi: WebapiService, public router: Router, public modalService: BsModalService) {
    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        if (this.router.url != '/notes') {
          this.currentUrl = this.router.url;
        }
      };
    });
  }

  ngOnInit(): void {
    let winVar: any = window;
    this.appVersion = winVar['APP_VERSION'];
    this.webApi.userDetail.subscribe((data: any) => { 
      this.userDetail = data;
     // this.is_ameyoEnabledSession =  ( ( sessionStorage.getItem("is_ameyoEnabledSession") && (this.userDetail?.role_name)?.indexOf('IB') != -1) || (this.userDetail?.role_name)?.indexOf('OB') != -1) ? true : false; 
     this.is_ameyoEnabledSession =   sessionStorage.getItem("is_ameyoEnabledSession") ? true : false; 
    });
    
    
   
    // let storage = localStorage.getItem('accessToken');
   // this.limsUrl = rootEnv.utilityAppUrl.lims_utility +/utility/+ '?token=' + storage + '&isCrmLogin=true';
    
  }


   goToLims(link: string) {
    const storage = localStorage.getItem('accessToken');
    let limsUrl = rootEnv.config.utilityAppUrl.lims_utility;
    window.open(`${limsUrl}/utility/?token=${storage}&isCrmLogin=true`, '_blank');
   }

   createLeadPopup() {
    let compTemplate: any = AddLeadComponent;
    let initState: any =
    {
      initialState: {
        title: "Create new lead",
        isUpdate: false,
        returnData: false,
        parentData: false

      },
      class: 'modal-lg'

    };

    let bsModalRef: any = this.modalService.show(compTemplate, initState);

    bsModalRef.onHidden?.subscribe((resData: any) => { this.popUpClosed(bsModalRef) });

  }

  popUpClosed(dataVar: any) {
    if (dataVar.content.returnData) {
      //this.getLeadList(false);
    }

  }


}
