import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { WebapiService } from 'src/app/services/webapi.service';
import { CommonService } from 'src/app/services/common.service';
import { apiUrl } from "src/environments/config";

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.scss']
})
export class UpdateCustomerComponent implements OnInit {

  formBG: any;
  resetFormVal: any;
  title: any;
  userDetail: any;
  customerDetails:any
  profile_id: number =0
  callerList :any;
  isUpdate: boolean = false;
  viewAfterUpdate:any = false;
  interaction_id:any;

  constructor(private webApi: WebapiService, public common: CommonService, private formBuilder: FormBuilder, public modalService: BsModalService, public modalRef: BsModalRef) { }



  ngOnInit(): void {

    this.webApi.userDetail.subscribe((data: any) => { this.userDetail = data });
    if(this.isUpdate)
    this.getCustomerDetails('getCustomerData');
    this.generateForm();

    this.callerList = [
      {
        key: "Patients",
        value: "Patients"
      },
      {
        key: "Sales",
        value: "Sales"
      },
      {
        key: "Client",
        value: "Client"
      },
      {
        key: "Corporate",
        value: "Corporate"
      },
      {
        key: "Lab",
        value: "Lab"
      },
      {
        key: "HLM",
        value: "HLM"
      },
      {
        key: "CC",
        value: "CC"
      },
      {
        key: "Corporate employee",
        value: "Corporate employee"
      },
      {
        key: "Mankind employee",
        value: "Mankind employee"
      },
      {
        key: "Pathkindlabs employee",
        value: "Pathkindlabs employee"
      }

    ]

  }

  ngAfterViewInit(): void {



  }



  closelModal() {
    this.modalRef.content.returnData = { updateList: true };
    this.modalRef.hide();

  }


  generateForm() {
    this.formBG = this.formBuilder.group(
      {

        "name": ['', [Validators.required]],
        "phone_number": ["",[Validators.minLength(10), Validators.maxLength(10)]],
        "email": [""],
        "phone_number2": [""],
        "gender": [""],
        "city": [""],
        "postcode": [""],
        "state": [""],
        "typeOfCaller":["", [Validators.required]],
        "isDNDActive": [false],
        "remarks":[""]

      });

    this.resetFormVal = { ...this.formBG.value }; 
  }











  resetForm() {

  }

  getCustomerDetails(dataVar:any){
    let dataParam: any = {
    config: {
      url: apiUrl.of(dataVar).url + this.profile_id + '/GetCustomerProfile',
      method: apiUrl.of(dataVar).method
    },
    payload: this.profile_id,
    //barLoader: true
  }
  this.webApi.apiResponse(dataParam).subscribe({
    next: res => {
      if (res.status) { 
        this.formBG.patchValue(res.customerProfile);
        if(this.userDetail.role_name=='Team IB'){
          if(res.customerProfile.email)
          this.formBG.get('email').disable();
          if(res.customerProfile.phone_number)
          this.formBG.get('phone_number').disable();
        }

      }
      else {
      }
    },
    error: error => {
      //console.log(error);

    }
  });

}



  formSubmit(dataVar: any) {
    let dataParam: any = {
      config: {
        url: apiUrl.of(dataVar).url + this.profile_id + '/UpdateProfile',
        method: apiUrl.of(dataVar).method
      },
      payload: {

        "name": this.formBG.get('name').value,
        "email": this.formBG.get('email').value,
        "phone_number": this.formBG.get('phone_number')?.value?.toString(),
        "phone_number2": this.formBG.get('phone_number2')?.value?.toString(),
        "gender": this.formBG.get('gender').value,
        "city": this.formBG.get('city').value,
        "postcode": this.formBG.get('postcode').value,
        "state": this.formBG.get('state').value,
        "typeOfCaller":this.formBG.get('typeOfCaller').value,
        "isDNDActive": this.formBG.get('isDNDActive').value,
        "remarks": this.formBG.get('remarks').value

      } 
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        
        if(this.viewAfterUpdate)
        {
          this.closelModal();

          this.viewAfterUpdate.click();
          //let targetUrl = window.location.origin + '/interaction-lead/' + this.interaction_id;
          //window.open(targetUrl, '_blank');
        }
        else
        {
          alert("Profile Updated!");
          this.closelModal();
          //location.reload();
        }
       
       
      },
      error: error => {
        //console.log(error);

      }
    });
  }

  formCreate(dataVar: any) {
    let dataParam: any = {
      config: {
        url: apiUrl.of(dataVar).url,
        method: apiUrl.of(dataVar).method
      },
      payload: {

        "name": this.formBG.get('name').value,
        "email": this.formBG.get('email').value,
        "phone_number": this.formBG.get('phone_number')?.value?.toString(),
        "phone_number2": this.formBG.get('phone_number2')?.value?.toString(),
        "gender": this.formBG.get('gender').value,
        "city": this.formBG.get('city').value,
        "postcode": this.formBG.get('postcode').value,
        "state": this.formBG.get('state').value,
        "typeOfCaller":this.formBG.get('typeOfCaller').value,
        "isDNDActive": this.formBG.get('isDNDActive').value

      } 
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
         //if (res.profileId>0) {   
        alert("Profile Created!")
        this.closelModal();
        location.reload();
       // }
       // else {
       // }
      },
      error: error => {
        //console.log(error);

      }
    });
  }


  updateView(dataVar:any)
  {

    this.viewAfterUpdate = dataVar;
    this.formSubmit('updateCustomer');
    

  }

}

