import { Component } from '@angular/core';
import { WebapiService } from 'src/app/services/webapi.service';
import { apiUrl } from 'src/environments/config';

@Component({
  selector: 'app-lead-check-list',
  templateUrl: './lead-check-list.component.html',
  styleUrls: ['./lead-check-list.component.scss']
})
export class LeadCheckListComponent {
  leadCheckList: any;

  constructor(private webApi: WebapiService) { }

  ngAfterViewInit(): void {
    // this.webApi.emitUserDetail();
    this.getLeadCheckList(false);
  }


  getLeadCheckList(dataVar: any) {
    let postParam = {
      "startpageindex": 1,
      "lastpageindex": 100
    }
    let dataParam: any = {
      config: apiUrl.of('leadCheckList'),
      payload: postParam,
      barLoader: true
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          this.leadCheckList = res.data.leadCheckList;
        }
      },
      error: error => {
        //console.log(error);
      }
    });
  }

}
