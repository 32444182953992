import { Component, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-pending-lead-alert',
  templateUrl: './pending-lead-alert.component.html',
  styleUrls: ['./pending-lead-alert.component.scss']
})
export class PendingLeadAlertComponent {
  title: any;
  parentData: any;
  userActivity: any = 0;
  logoutBufferTime: number = 120000;

  constructor(public modalRef: BsModalRef, private webApi: WebapiService) {
    this.setTimeout();
    this.webApi.userDetail.subscribe((res: any) => {
      this.logoutBufferTime = res.logout_buffer_time ? Number(res.logout_buffer_time) * 60 * 1000 : 120000;
    });
  }

  closelModalAndLogout() {
    clearTimeout(this.userActivity);
    this.modalRef.content.returnData = { logout: true };
    this.modalRef.hide();
  }

  closeWithStayLogin() {
    clearTimeout(this.userActivity);
    this.modalRef.content.returnData = { logout: false };
    this.modalRef.hide();
    window.location.reload();
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.closelModalAndLogout(), this.logoutBufferTime);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

}
