import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { rootEnv } from "src/environments/config";
import * as signalR from '@microsoft/signalr';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class LeadSocketService {

  public socketData: BehaviorSubject<any> = new BehaviorSubject({});
  socket: any;

  forLeadPage: BehaviorSubject<any> = new BehaviorSubject(0);
  forLeadDetailPage: BehaviorSubject<any> = new BehaviorSubject(0);
  forAgentPage: BehaviorSubject<any> = new BehaviorSubject(0);
  hubUrl: string;


  constructor(private common: CommonService) {
    // this.hubUrl = 'https://crmsocketnet.azurewebsites.net/continuousDataHubUser';
    this.hubUrl = `${rootEnv.config.webSocket}/continuousDataHubUser`;
  }







  async conectIO() {
    this.disconnect();
    //for signalR
    try {
      this.socket = new signalR.HubConnectionBuilder()
        .withUrl(this.hubUrl)
        .withAutomaticReconnect()
        .build();

      this.socket.start().then(() => {
        // console.log(`SignalR connection success! connectionId: ${ this.socket.connectionId } `);
        this.common.socketId = this.socket?.connectionId;
      });
    }
    catch (error) {
      console.log(`SignalR connection error: ${ error } `);
      // setTimeout(this.conectIO, 5000);
    }

    // this.socket?.onclose(() => {
    //   setTimeout(() => {
    //     this.socket?.start().then(() => {
    //       console.log("connection started");
    //     }).catch((err: any) => console.log(err));
    //   }, 5000);
    // });

    // for we socket
    //   let self = this;
    //  this.socket = io(rootEnv.config.webSocket, {path: "/websocket/socket.io"});
    //  console.log(' this.socket :>> ',  this.socket);
    //   // this.socket = io(rootEnv.config.webSocket);


    //   this.socket.on('connect', function() 
    //   {


    //   });

    //   this.socket.on('connect_failed', function() {
    //     document.write("Sorry, there seems to be an issue with the connection!");
    //  })

  }


  disconnect() {
    this.socket?.stop();
    // this.socket?.disconnect();

  }



  public emitData(dataVar: any) {
    // this.socket?.emit(dataVar.target, {...dataVar.dataParam});
      this.socket?.invoke('CRMSocket1', dataVar.target);
  }

  public getData = () => {
    this.socket?.on('afterConnection', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('leadRowStatus', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('ReminderNotification', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('freeLeadNotification', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('samplifyNotification', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });
    

    this.socket?.on('leadAssigned', (dataParam:any) =>{
      
      this.socketData.next(dataParam);
    });

    this.socket?.on('roundRobin', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('reminderORcancel', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('bookingLead', (dataParam:any) =>{
      this.socketData.next(dataParam);
    });

    this.socket?.on('appReload', (dataParam:any) =>{

      this.socketData.next(dataParam);
    });




    this.socket?.on('agentEvent', (dataParam:any) =>{
      this.socketData.next({targetEvent:'agentEvent', ...dataParam});
    });



    return this.socketData.asObservable();
  };



}
