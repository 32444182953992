import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';

import {ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import {PaginationModule} from 'src/app/shared/component/pagination/pagination.module';

import {HeaderComponent} from "./header.component";
import {SidenavComponent} from "../sidenav/sidenav.component";
import { SingleLinkComponent } from '../sidenav/single-link/single-link.component';
import { MultiLinkComponent } from '../sidenav/multi-link/multi-link.component';

import { LeadsFreeUsersComponent } from './leads-free-users/leads-free-users.component';
import { PendingLeadAlertComponent } from './pending-lead-alert/pending-lead-alert.component';




@NgModule({
  declarations: [HeaderComponent, SidenavComponent,SingleLinkComponent,MultiLinkComponent,LeadsFreeUsersComponent, PendingLeadAlertComponent],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    PaginationModule
  ],
  exports:[HeaderComponent, SidenavComponent,SingleLinkComponent,MultiLinkComponent]
})
export class HeaderModule { }
