<div class="bg-secondary text-white px-1 pt-1 border">
    <h6>Lead Check List!</h6>
</div>
<div class="text-black list-overflow">
    <div class="shadowSm1">
        <div class="customTabel" #fixDiv>
            <div class="listRow bg-light">
                <div>Key</div>
                <div>Value</div>
            </div>
        </div>
        <div class="customTabel text-break">
            <ng-container *ngFor="let item of leadCheckList;">
                <div class="listRow">
                    <div>
                        <div class="labelText">Key</div>
                        <div>{{item.key | titlecase}}</div>
                    </div>
                    <div>
                        <div class="labelText">Value</div>
                        <div>{{item.value}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>