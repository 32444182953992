<div class="footer" [ngClass]="{'ameyoZindex':userDetail.rights?.ameyo_on_top}">

   
<div *ngIf="( (userDetail?.rights?.ameyo_calling) || userDetail?.is_ameyo_required) && is_ameyoEnabledSession"><app-ameyo-calling [hidden]="!this.userDetail || currentUrl == '/'"></app-ameyo-calling></div>
<div class="m-auto text-center">&#169; Pathkind Labs {{todayDate | date:"YYYY"}} (Version {{appVersion}})</div>
<div>
    <ng-container *ngIf="this.userDetail && currentUrl != '/'">
        <div class="check-list-page" *ngIf="!isShowLeadpage" [@animation]>
            <app-lead-check-list></app-lead-check-list>
        </div>
        <ng-container *ngIf="!this.userDetail?.rights?.hide_dashboard">
            <div class="d-flex gap-1 left-buttons" *ngIf="!this.userDetail.rights.is_3rd_party">
                <div class="footer-buttons btn btn-info border-bottom-0" *ngIf="userDetail.rights?.add_new_lead">
                    <a class="text-decoration-none text-info"  (click)="createLeadPopup()"
                        target="_blank"><i class="bi bi-database-add"></i> <span class="cons"> Add Lead</span></a>
                </div>
                <div class="footer-buttons btn btn-info border-bottom-0"
                    (click)=" isShowLeadpage = !isShowLeadpage">
                    <i class="bi"
                        [ngClass]="{'bi-x': !isShowLeadpage, 'bi-list-check': isShowLeadpage,  'm-1': isShowLeadpage}"></i><span
                        class="cons">
                        {{isShowLeadpage ? 'Check List': 'Close'}}</span>
                </div>
                <!-- <div class="footer-buttons btn btn-info border-bottom-0">
                    <a class="text-decoration-none text-info" (click)="goToLims('https://report-status.azurewebsites.net/ui/')" href="javascript:void(0)"><i class="bi bi-link-45deg"></i> <span class="cons">Lims Utility</span></a>
                </div> -->
                <div class="footer-buttons btn btn-info border-bottom-0">
                    <a class="text-decoration-none text-info" href="javascript://" (click)="goToLims('')"><i class="bi bi-link-45deg"></i> <span class="cons">Lims Utility</span></a>
                </div>
                <div class="footer-buttons btn btn-info border-bottom-0">
                    <a class="text-decoration-none text-info" href="https://edos.pathkindlabs.com/" target="_blank"><i
                            class="bi bi-link-45deg"></i> <span class="cons">Edos</span></a>
                </div>
            </div>
        </ng-container>     


    </ng-container>
</div>
    
    
    

    


</div>