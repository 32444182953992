<div class="toastContainer" *ngIf="webApi.toastMsg?.length>0">

    <div *ngFor="let toast of webApi.toastMsg"> 

       
     <ng-container *ngIf="!isTemplate(toast)">

      
        <div  *ngIf="toast.type=='error'"  class="alert alert-danger alert-dismissible fade show" role="alert" #toastEle (onCreate)="addOnToast(toastEle,toast)">
            <strong>Error!</strong> {{toast.textOrTpl}}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="webApi.removeToast(toast);"></button>
        </div>

        <div  *ngIf="toast.type=='success'"  class="alert alert-success alert-dismissible fade show" role="alert" #toastEle (onCreate)="addOnToast(toastEle,toast)">
            <strong>Success!</strong> {{toast.textOrTpl}}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="webApi.removeToast(toast);"></button>
        </div>

        <div  *ngIf="toast.type=='notification'"  class="alert alert-info alert-dismissible fade show" role="alert" #toastEle>
            <strong>Notification!</strong> {{toast.textOrTpl}}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="webApi.removeToast(toast);"></button>
        </div>

        

     </ng-container>
 
    
 
    </div> 
 
 
 
 </div>