import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {WebapiService} from "src/app/services/webapi.service";

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate, CanActivateChild {

  constructor(private webApi:WebapiService, private router:Router, private http: HttpClient,){}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):Promise<any> {
    
      let userRnR:any;

      if(this.webApi.isLoggedIn())
      { 

        userRnR = await this.webApi.getRolesAndRights();
        
        let validPath = this.checkValidRout(userRnR?.menus, route, state);

        return validPath;
        
        
      }
      else
      {
        this.router.navigate(["login"]);
        return false;
      }

  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }


  checkValidRout(menus:any, crnRoute:any,crnState:any)
  {
    let outData:any = false;
    if(!menus)
    {
      return false;
    }

    for(let m = 0; m<menus.length; m++)
    {
      let cRoute = crnState.url
      if((cRoute).indexOf(menus[m].module_url)>0 )
      {
        outData = true;
      }

      if(menus[m].module_url == "#" && menus[m].submenu.length>0)
      {
        for(let sm = 0; sm<menus[m].submenu.length; sm++)
        {
          if((cRoute).indexOf(menus[m].submenu[sm].module_url)>0 )
          {
            outData = true;
            break;
          }
        }

      }

      if(outData)
      {
        break
      }


    }
   
    return outData;

  }
  
}
