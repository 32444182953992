import { Component, OnInit } from '@angular/core';
import {BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  msg:any;
  title:any;
  returnData:any;

  constructor(public modalRef: BsModalRef) {}



  ngOnInit(): void {
    
  }


  closelModal(DataVar:any)
  {
    this.returnData={confirm:DataVar};
    this.modalRef.hide();
    
  }


}
