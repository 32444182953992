<div class="alertPopup">


<div class="modal-header">
    <h5 class="modal-title pull-left"><i class="bi bi-exclamation-triangle"></i> {{title || 'Alert'}}</h5>
        <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
</div>
    <div class="modal-body text-center">
              
        <h5 class="text-secondary mt-2">{{msg}}</h5>
        
    </div>

    <div class="modal-footer p-2">
        <button type="button" class="btn btn-light" aria-label="Close" (click)="closelModal(false)">NO</button>
        <button type="button" class="btn btn-primary" aria-label="Close" (click)="closelModal(true)">Yes</button>
    </div>
     
</div>