import { Component } from '@angular/core';
import {BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  msg:any;
  title:any

  constructor(public modalRef: BsModalRef) {}

}
