import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { WebapiService } from 'src/app/services/webapi.service';
import { CommonService } from 'src/app/services/common.service';
import { apiUrl } from "src/environments/config";

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {

  formBG: any;
  resetFormVal: any;
  title: any;
  parentData: any
  isUpdate: any;
  leadsource: any;
  leadmedium: any;
  leadcampaign: any;
  genderList: any;
  collectionSlots: any;

  filedFreeze:any = {'leadsource':'apikey', 'leadmedium':'lead_medium_id', 'leadcampaign':'lead_campaign_id'}

  calendarConfig: any = { dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, containerClass: 'theme-dark-blue', maxDate: new Date() };
  collectionDateConfig: any = { dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, containerClass: 'theme-dark-blue', minDate: new Date() };
  annivCalendarConfig: any = { dateInputFormat: 'DD-MM', showWeekNumbers: false, containerClass: 'theme-dark-blue', maxDate: new Date() };

  get prescriptionList() {
    return this.formBG?.controls["prescription"] as FormArray;
  }




  constructor(private webApi: WebapiService, public common: CommonService, private formBuilder: FormBuilder, public modalService: BsModalService, public modalRef: BsModalRef) { }



  ngOnInit(): void {


    this.dropDownList({ type: 'leadsource' });
    this.dropDownList({ type: 'leadmedium' });
    this.dropDownList({ type: 'leadcampaign' });
    this.dropDownList({ type: 'gender', varKey: 'genderList' });
    // this.dropDownList({ type: 'slot', varKey: 'collectionSlots' });

    this.generateForm();
  }

  ngAfterViewInit(): void {



  }



  closelModal() {
    this.modalRef.content.returnData = { updateList: true };
    this.modalRef.hide();

  }


  generateForm() {
    this.formBG = this.formBuilder.group(
      {
        "apikey": ['03f2c005032a4cc48744321fcc8ca7c9', [Validators.required]],
        "name": ['', [Validators.required]],
        "age": !this.isUpdate ? ["", [Validators.maxLength(3)]] : ["", [Validators.required, Validators.maxLength(3)]],
        "dob": !this.isUpdate ? [""] : [""],
        "anniversary": [""],
        "mobile": ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        "alternate_mobile": ['', [Validators.minLength(10), Validators.maxLength(10)]],
        "email": ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        "gender": [""],
        "passport": [""],
        "state": [""],
        "city": [""],
        "locality": [""],
        "pincode": ['', [Validators.minLength(6), Validators.maxLength(6)]],
        "address": [""],

        "lead_medium_id": ["12", [Validators.required]],
        "lead_campaign_id": ["15", [Validators.required]],

        "collection_date": [""],
        "collection_slot": [""],

        "utm_content": [""],
        "utm_name": [""],
        "utm_source": [""],
        "utm_medium": [""],
        "utm_campaign": [""],
        "udf": [""],
        "description": [""],
        "prescription": this.formBuilder.array([]),
        "remarks": ['']
      });

    this.resetFormVal = { ...this.formBG.value };
    if (this.isUpdate) {
      this.isUpdate.dob = this.common.dateFormat(this.isUpdate?.dob, 'dd-MM-yyyy');
      this.isUpdate.collection_date = this.common.dateFormat(this.isUpdate?.collection_date, 'dd-MM-yyyy');
      this.formBG.controls['mobile']?.disable();
      this.formBG.controls['utm_content']?.disable();
      this.formBG.controls['utm_name']?.disable();
      this.formBG.controls['utm_source']?.disable();
      this.formBG.controls['utm_medium']?.disable();
      this.formBG.controls['utm_campaign']?.disable();
      this.formBG.controls['apikey']?.disable();
      this.formBG.controls['lead_medium_id']?.disable();
      this.formBG.controls['lead_campaign_id']?.disable();
      this.formBG.controls['udf']?.disable();

      this.formBG.patchValue(this.isUpdate);
      if (!this.isUpdate?.gender) {
        this.formBG.get('gender').setValue("");
      }
    }
  }




  dropDownList(dataVar: any) {

    let self: any = this;
    self[dataVar.varKey || dataVar.type] = [];


    let dataParam: any = {
      config: apiUrl.of('dropDown'),
      payload: { bind_type: dataVar.type, param: dataVar.param ? dataVar.param : [] },
      barLoader: true
    }


    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          self[dataVar.varKey || dataVar.type] = res.data?.item_list;
          if (dataVar.type == 'slot')
          {
             this.setSlotSelected();
          }
          else if( (dataVar.type == 'leadsource' || dataVar.type == 'leadmedium' || dataVar.type == 'leadcampaign') && res.data?.item_list.length == 1)
          {
              let val = res.data.item_list[0].key;
              this.formBG.get(this.filedFreeze[dataVar.type]).setValue(val);
          }
          
           
        }

      }
    });

  }



  addPrescription(listData: any) {
    for (let i = 0; i < listData.length; i++) {
      const formArr = this.formBuilder.group({
        "filename": [listData[i].filename],
        "filetype": [listData[i].filetype],
        "base64": [listData[i].base64],
        //"fileurl":[""],

      });
      this.prescriptionList.push(formArr);
    }
  }

  deletAttachedFile(item: any) {

    let indx = this.prescriptionList.controls.indexOf(item);

    this.prescriptionList.controls.splice(indx, 1);


  }


  fileDetail(evt: any) {
    const file = evt.target.files[0];

    let fileSize = (file.size / (1024 * 1024));

    if (fileSize > 5) {
      let errorTxt: any = "File size is greater than 5 MB";
      this.webApi.showToast(errorTxt, { type: 'error' });
      //this.common.alertMsg({text:"File size is greater than 2 MB"})
      return;
    }

    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let bs64Type = (reader.result).split(",");
      let dataObj = { filename: file.name, base64: bs64Type[1], filetype: bs64Type[0] };
      this.addPrescription([dataObj]);

      evt.target.value = "";
      evt.target.files.FileList = [];

    };

  }

  resetForm() {

  }

  itemUpdate() {

    let updateValue = { ...this.formBG.getRawValue(), ...{ lead_capture_id: this.isUpdate.lead_capture_id } };

    this.formSubmit(updateValue);

  }



  formSubmit(dataVar: any) {

    let dataParam: any = {
      config: (dataVar) ? apiUrl.of('updateLead') : apiUrl.of('createLead'),
      payload: (dataVar) ? dataVar : this.formBG.getRawValue()
    }

    if (!dataParam.payload.age) {
      dataParam.payload.age = 0;
    }
    dataParam.payload.dob = this.common.dateFormat(dataParam.payload.dob, 'yyyy-MM-dd');
    dataParam.payload.anniversary = this.common.dateFormat(dataParam.payload.anniversary, 'MM-dd');
    dataParam.payload.collection_date = this.common.dateFormat(dataParam.payload.collection_date, 'yyyy-MM-dd');

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

        if (res.is_success) {

          this.closelModal();
          this.modalRef.content.returnData = { isUpdate: true, popUp: "leadAssign" };
        }


      }
    });

  }

  calculateAgeAndDob() {
    if (this.formBG.controls['age'].value) {
      const aYearFromToday = new Date();
      aYearFromToday.setFullYear(aYearFromToday.getFullYear() - Number(this.formBG.controls['age'].value));
      this.formBG.patchValue({ dob: aYearFromToday });
    } else {
      this.formBG.patchValue({ dob: '' });
    }
  }

  onValueChange(value: Date): void {
    if (value) {
      let dob: any = new Date(value);
      let today: any = new Date();
      let timediff = Math.abs(today - dob);
      let age = Math.floor((timediff / (1000 * 3600 * 24)) / 365);
      this.formBG.patchValue({ age: age });
    } else if (this.isUpdate) {
      this.formBG.patchValue({ age: this.isUpdate.age });
    } else {
      this.formBG.patchValue({ age: 0 });
    }
  }

  getCollectionSlot(dataVar: any) {
    this.collectionSlots = [];
    let date = this.common.dateFormat(dataVar, 'yyyy-MM-dd');
    this.dropDownList({ type: 'slot', varKey: 'collectionSlots', param: [{ "key": "ddl_id", "value": date }] });
  }

  setSlotSelected() {
    let sDate = this.formBG.getRawValue().collection_date;
    let sSlot = this.formBG.getRawValue().collection_slot;
    const existing = this.collectionSlots?.filter((item: any) => item.key == sSlot);
    if (sDate && existing?.length) {
      this.formBG.get('collection_slot').setValue(sSlot);
    } else {
      this.formBG.get('collection_slot').setValue('');
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  aphabetOnly(event: any) {
    let charCode = event.keyCode;
    if ((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) {
      return true;
    } else {
      return false;
    }
  }

}
