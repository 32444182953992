import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { WebapiService } from 'src/app/services/webapi.service';
import { CommonService } from 'src/app/services/common.service';
import { apiUrl } from "src/environments/config";

@Component({
  selector: 'app-leads-free-users',
  templateUrl: './leads-free-users.component.html',
  styleUrls: ['./leads-free-users.component.scss']
})
export class LeadsFreeUsersComponent implements OnInit, AfterViewInit {

  formBG: any;
  resetFormValue: any;
  title: any;
  isUpdate: any;
  leadsfreeUsers: any;




  constructor(private webApi: WebapiService, public common: CommonService, private formBuilder: FormBuilder, public modalService: BsModalService, public modalRef: BsModalRef) { }

  ngOnInit(): void {

    this.getleadsfreeUsers('');
  }

  ngAfterViewInit(): void {

  }

  getleadsfreeUsers(dataVar: any) {

    let dataParam: any = {
      config: apiUrl.of('leadsfreeUsers'),
      payload: {}
    }



    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

        if (res.is_success) {
          this.leadsfreeUsers = res.data.free_Users;
        }


      }
    });

  }

  closelModal() {
    this.modalRef.content.returnData = { updateList: true };
    this.modalRef.hide();

  }





  formSubmit(dataVar: any) {



  }

 

}



