import { Component, TemplateRef, OnInit } from '@angular/core';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss']
})
export class ToastsContainerComponent implements OnInit {

  
  constructor(public webApi: WebapiService) {}




  ngOnInit(): void {
    
    for(let i=0; i<20; i++)
    {
      //this.webApi.showToast("new2",{type:'success'});
    }
    
    
  }



  isTemplate(toast:any) 
  {
		return toast.textOrTpl instanceof TemplateRef;
	}


 addOnToast(elm:any,item:any)
  {

    //setTimeout(() => { elm.lastChild.click();}, 2700);
    setTimeout(() => { this.webApi.removeToast(item);}, 2700);
    
  } 

  

}
