<div class="modal-header bg-secondary">
  <div class="heading3 before1">{{title}}</div>
  <button type="button" class="close btn-close pull-right" aria-label="Close" hidden>
    <span aria-hidden="true" class="visually-hidden text-white">&times;</span>
  </button>
  <div class="closeBtn" (click)="modalRef.hide()"><i class="bi bi-x-square"></i></div>
</div>
<div class="modal-body shadowSm1 custum-overflow">

  <div class="mt-3">
    <form class="row g-3" [formGroup]="formBG">


      <div class="col-3">
        <label class="form-label">Name</label>
        <input type="text" appOnlyAlphabet="true" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'name'})}" formControlName="name"
          placeholder="Enter name" (keypress)="aphabetOnly($event)" />
      </div>

      <div class="col-3">
        <label for="mobile" class="form-label">Mobile</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'mobile'})}" id="mobile"
          formControlName="mobile" placeholder="Enter mobile" appOnlyNumber="true" maxLength="10"
          (keypress)="numberOnly($event)">
      </div>

      <div class="col-3">
        <label for="alternate_mobile" class="form-label">Alternate mobile</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'alternate_mobile'})}"
          id="alternate_mobile" formControlName="alternate_mobile" placeholder="Enter alternate mobile"
          appOnlyNumber="true" maxLength="10">
      </div>

      <div class="col-3">
        <label for="email" class="form-label">Email</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'email'})}" id="email"
          formControlName="email" placeholder="Enter email Id">
      </div>

      <div class="col-2">

        <label class="form-label">Lead source</label>
        <select class="form-select" [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'apikey'})}"
          formControlName="apikey">
          <option value="">Select</option>
          <option [value]="item.key" *ngFor="let item of leadsource">{{item.value}}</option>

        </select>
      </div>

      <div class="col-2">
        <label class="form-label">Lead medium</label>
        <select class="form-select"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'lead_medium_id'})}"
          formControlName="lead_medium_id">
          <option value="">Select</option>
          <option [value]="item.key" *ngFor="let item of leadmedium">{{item.value}}</option>
        </select>

      </div>

      <div class="col-2">
        <label class="form-label">Campaign name</label>
        <select class="form-select"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'lead_campaign_id'})}"
          formControlName="lead_campaign_id">
          <option value="">Select</option>
          <option [value]="item.key" *ngFor="let item of leadcampaign">{{item.value}}</option>
        </select>

      </div>

      <div class="col-3">
        <label class="form-label">Collection date</label>
        <input type="text" class="form-control" bsDatepicker [bsConfig]="collectionDateConfig" placeholder="DD-MM-YYYY"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'collection_date'})}"
          formControlName="collection_date" (bsValueChange)="getCollectionSlot($event)" s onkeydown="return false">

      </div>


      <div class="col-3">
        <label class="form-label">Collection slot</label>
        <select class="form-select"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'collection_slot'})}"
          formControlName="collection_slot">
          <option value="" selected>Select</option>
          <option [value]="item.key" *ngFor="let item of collectionSlots">{{item.value}}</option>

          <!-- <option value="09:00 AM">09:00 AM</option>
          <option value="10:30 AM">10:30 AM</option> -->
          <!--<option [value] ="item.key" *ngFor="let item of leadcampaign">{{item.value}}</option> -->
        </select>

      </div>

      <div class="col-2">
        <label for="gender" class="form-label">Gender</label>
        <select class="form-select" [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'gender'})}"
          formControlName="gender">
          <option value="" selected disabled>Select</option>
          <option [value]="item.key" *ngFor="let item of genderList">{{item.value}}</option>
        </select>


      </div>

      <div class="col-2">
        <label class="form-label">Age</label>
        <div class="d-flex justify-content-bettwen">
          <input type="number" class="form-control"
            [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'age'})}" formControlName="age"
            placeholder="Enter age" appOnlyNumber="true" maxLength="2" max="100">
          <!-- <input type="text" class="form-control" bsDatepicker [bsConfig]="calendarConfig" placeholder="DD-MM-YYYY"
            [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'dob'})}" formControlName="dob"
            placeholder="DD-MM-YYYY" (bsValueChange)="onValueChange($event)"> -->
        </div>
      </div>

      <div class="col-2">
        <label class="form-label">Birthday</label>
        <div class="d-flex justify-content-bettwen">
          <!-- <input type="text" class="form-control"
            [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'age'})}" formControlName="age"
            placeholder="Enter age" appOnlyNumber="true" maxlength="3" (input)="calculateAgeAndDob()"> -->
          <input type="text" class="form-control" bsDatepicker [bsConfig]="calendarConfig" placeholder="DD-MM-YYYY"
            [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'dob'})}" formControlName="dob"
            placeholder="DD-MM-YYYY" (bsValueChange)="onValueChange($event)">
        </div>
      </div>

      <div class="col-3">
        <label class="form-label">Anniversary</label>
        <div class="d-flex justify-content-bettwen">
          <input type="text" class="form-control" formControlName="anniversary" placeholder="DD-MM" bsDatepicker
            [bsConfig]="annivCalendarConfig">
        </div>
      </div>

      <div class="col-3">
        <label class="form-label">Passport</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'passport'})}" formControlName="passport"
          placeholder="Enter passport">
      </div>

      <div class="col-2">
        <label class="form-label">State</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'state'})}" formControlName="state"
          placeholder="Enter State">
      </div>

      <div class="col-2">
        <label class="form-label">City</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'city'})}" formControlName="city"
          placeholder="Enter city">
      </div>

      <div class="col-2">
        <label class="form-label">Locality</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'locality'})}" formControlName="locality"
          placeholder="Enter locality">
      </div>

      <div class="col-4">
        <label class="form-label">Address</label>
        <input type="text" class="form-control"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'address'})}" formControlName="address"
          placeholder="Enter address">
      </div>

      <div class="col-2">
        <label class="form-label">Pincode</label>
        <input type="text" class="form-control" formControlName="pincode"
          [ngClass]="{'notValid':common.notValidField({fbGroup:formBG, field:'pincode'})}" placeholder="Enter pincode"
          appOnlyNumber="true" maxLength="6">
      </div>

      <hr>
      <div class="col-2">
        <label class="form-label">UTM content</label>
        <input type="text" class="form-control" formControlName="utm_content" placeholder="Enter utm content">
      </div>

      <div class="col-2">
        <label class="form-label">UTM name</label>
        <input type="text" class="form-control" formControlName="utm_name" placeholder="Enter utm name">
      </div>

      <div class="col-2">
        <label class="form-label">UTM source</label>
        <input type="text" class="form-control" formControlName="utm_source" placeholder="Enter utm source">
      </div>

      <div class="col-2">
        <label class="form-label">UTM medium</label>
        <input type="text" class="form-control" formControlName="utm_medium" placeholder="Enter utm medium">
      </div>

      <div class="col-2">
        <label class="form-label">UTM campaign</label>
        <input type="text" class="form-control" formControlName="utm_campaign" placeholder="Enter utm campaign">
      </div>

      <div class="col-2">
        <label class="form-label">UDF</label>
        <input type="text" class="form-control" formControlName="udf" placeholder="Enter udf">
      </div>




      <div class="col-12">

        <div class="fileUploader" *ngIf="!isUpdate">


          <div>
            <label for="filename" class="form-label">Upload File</label>
            <input class="form-control" (change)="fileDetail($event)" [disabled]="prescriptionList.controls.length>=3"
              accept="**" type="file" id="filename">
          </div>

          <div class="docList">
            <ng-container formArrayName="prescription">
              <div *ngFor="let item of prescriptionList.controls; let n = index;">
                <div><img src="assets/images/imageIcon.png" /></div>
                <p class="text-break p-0 m-0">{{item.value.filename}}</p>
                <div class="delIcon" (click)="deletAttachedFile(item)"><i class="bi bi-trash-fill"></i></div>


              </div>
            </ng-container>
          </div>

        </div>

      </div>


      <div class="col-12">
        <label class="form-label">Lead remark</label>
        <input type="text" class="form-control" formControlName="remarks" placeholder="Enter Remark">
      </div>

      <div class="col-12">
        <label class="form-label">Description</label>
        <input type="text" class="form-control" formControlName="description" placeholder="Enter description">
      </div>

      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary" [ngClass]="{'inActive':formBG.status != 'VALID'}"
          (click)="formSubmit(false)" *ngIf="! isUpdate">Submit</button>
        <button type="submit" class="btn btn-primary" [ngClass]="{'inActive':formBG.status != 'VALID'}"
          (click)="itemUpdate()" *ngIf="isUpdate">Update</button>
      </div>
    </form>
  </div>

</div>