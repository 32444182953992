<div class="modal-header bg-secondary">
  <div class="heading3 before1">{{ title }}</div>
  <button
    type="button"
    class="close btn-close pull-right"
    aria-label="Close"
    hidden
  >
    <span aria-hidden="true" class="visually-hidden text-white">&times;</span>
  </button>
  <div class="closeBtn" (click)="modalRef.hide()">
    <i class="bi bi-x-square"></i>
  </div>
</div>
<div class="modal-body shadowSm1">
  <div class="mt-3">
    <form class="row g-3" [formGroup]="formBG">
      <div class="col-4">
        <label class="form-label">Name</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({ fbGroup: formBG, field: 'name' })
          }"
          formControlName="name"
          placeholder="Enter name"
        />
      </div>

      <div class="col-4">
        <label for="gender" class="form-label">Gender</label>
        <select
          class="form-select"
          [ngClass]="{
            notValid: common.notValidField({ fbGroup: formBG, field: 'gender' })
          }"
          formControlName="gender"
        >
          <option value="">Select</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div class="col-4">
        <label for="typeOfCaller" class="form-label">Type of Customer</label>
        <select
          class="form-select" 
          formControlName="typeOfCaller"
          [ngClass]="{
            notValid: common.notValidField({ fbGroup: formBG, field: 'typeOfCaller' })
          }"
        >
        <option value ="">Select</option>
        <option [value] ="item.key" *ngFor="let item of callerList">{{item.value}}</option>
        </select>
      </div>

      <hr />
      <div class="col-4">
        <label for="mobile" class="form-label">Mobile  <span *ngIf="formBG.get('isDNDActive').value" class="text-danger"> (Marked as DND)</span></label>
        <input
          type="number"  maxlength="10" onKeyPress="if(this.value.length==10) return false;"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({
              fbGroup: formBG,
              field: 'phone_number'
            })
          }"
          id="phone_number"
          formControlName="phone_number"
          placeholder="Enter mobile"
        />
      </div>

      <div class="col-4">
        <label for="mobile2" class="form-label">Mobile 2</label>
        <input
          type="number" appOnlyNumber="true"  maxlength="10" onKeyPress="if(this.value.length==10) return false;"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({
              fbGroup: formBG,
              field: 'phone_number2'
            })
          }"
          id="phone_number"
          formControlName="phone_number2"
          placeholder="Enter mobile"
        />
      </div>

      <div class="col-4">
        <label for="Email" class="form-label">Email</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({ fbGroup: formBG, field: 'email' })
          }"
          id="email"
          formControlName="email"
          placeholder="Enter email"
        />
      </div>

      <hr />

       <div class="col-4">
        <label for="city" class="form-label">City</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({
              fbGroup: formBG,
              field: 'city'
            })
          }" 
          formControlName="city"
          placeholder="Enter City"
        />
      </div> 

      <div class="col-4">
        <label for="postcode" class="form-label">Post Code</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({
              fbGroup: formBG,
              field: 'postcode'
            })
          }" 
          formControlName="postcode"
          placeholder="Enter area Postcode"
        />
      </div>

      <div class="col-4">
        <label for="state" class="form-label">State</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({ fbGroup: formBG, field: 'state' })
          }" 
          formControlName="state"
          placeholder="Enter State"
        />
      </div>  

      <hr/>
      
      <div class="col-12 cs-form">
        <label for="remarks" class="form-label">Remarks</label>
        <input
          type="text"
          class="form-control"
          [ngClass]="{
            notValid: common.notValidField({
              fbGroup: formBG,
              field: 'remarks'
            })
          }" 
          formControlName="remarks"
          placeholder="Enter Remarks"
        />
        </div>

      <hr/>
      
      <div class="col-6 cs-form">
        <input class="form-check-input" type="checkbox"   formControlName="isDNDActive">
            <label  style="margin-left: 10px;"> 
                            <span class="checkmark"> </span>
                             Mark Customer Number as DND
                        </label> 
        </div>

      <div class="d-flex justify-content-end gap-2">

        <a href="/interaction-lead/{{interaction_id}}" target="_blank" #interactionLink hidden> new link </a>
        <button
          type="submit" *ngIf="isUpdate && interaction_id"
          class="btn btn-primary"
          [ngClass]="{ inActive: formBG.status != 'VALID' }"
          (click)="updateView(interactionLink)"
        >
          Update & View
        </button>

        <button
          type="submit" *ngIf="isUpdate"
          class="btn btn-primary"
          [ngClass]="{ inActive: formBG.status != 'VALID' }"
          (click)="formSubmit('updateCustomer')"
        >
          Update
        </button>
        <button
          type="submit" *ngIf="!isUpdate"
          class="btn btn-primary"
          [ngClass]="{ inActive: formBG.status != 'VALID' }"
          (click)="formCreate('createCustomerData')"
        >
          Create Customer
        </button>
      </div>
    </form>
  </div>
</div>
