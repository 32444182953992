import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OnCreateDirective} from "src/app/shared/directives/on-create/on-create.directive";




@NgModule({
  declarations: [OnCreateDirective],
  imports: [
    CommonModule
  ],
  exports:[OnCreateDirective]
})
export class OnCreateModule { }
