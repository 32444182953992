import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Navigation, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

import { WebapiService } from 'src/app/services/webapi.service';
import { CommonService } from 'src/app/services/common.service';
import { apiUrl, rootEnv } from "src/environments/config";

import { LeadSocketService } from 'src/app/services/lead-socket.service';

import { LeadsFreeUsersComponent } from './leads-free-users/leads-free-users.component';
import { PendingLeadAlertComponent } from './pending-lead-alert/pending-lead-alert.component';
import { ConfirmComponent } from 'src/app/shared/component/popup/confirm/confirm.component';
import { Subject } from 'rxjs';
import { NotesComponent } from 'src/app/modules/notes/notes/notes.component';
import { AlertComponent } from 'src/app/shared/component/popup/alert/alert.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent implements OnInit {

  currentRoute: any;
  closeSideNav: any;
  barLoader: any;
  userDetail: any;
  breadCrucmbList: any = [];
  showHeader: any = false;
  headerNotFor: any = ['', 'login', 'payment'];
  socketEvent: any;

  ReminderNotification: any = 0;
  freeLeadNotification: any = 0;
  samplifyNotification: any = 0;

  notification: any = 0;
  rights: any = {}
  isActivePop: boolean = true;
  userActivity: any;
  agentTarget: any;
  notes: any;
  isShowNotes: boolean = false;;
  reminderleadItem: any = [];
  popupNotificationTime: number = 5 * 60 * 1000;

  currentEnv: any = rootEnv;

  worktype: any = {
    is_call_active: false,
    is_email_active: false,
    is_surbochat_active: false,
    is_socialmedia_active: false,
    is_ticket_active: false,
    is_Manual_Task: false
  };


  is_call_active: boolean = false;
  is_email_active: boolean = false;
  is_socialmedia_active: boolean = false;
  is_surbochat_active: boolean = false;
  is_ticket_active: boolean = false;
  is_Manual_Task: boolean = false


  constructor(private webSocket: LeadSocketService, private common: CommonService, public webApi: WebapiService, public router: Router, public activatRoute: ActivatedRoute, public modalService: BsModalService) {
    this.currentRoute = "";

    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        let route = (val.urlAfterRedirects).substring(1, (val.urlAfterRedirects).length);

        let split1 = route.split("?")[0];
        this.currentRoute = split1.split("/")[0];

        this.closeSideNav?.nativeElement.click();

        // this.breadCrucmb(route);

        this.showHeader = (this.headerNotFor.indexOf(this.currentRoute) < 0) ? true : false;



        if (this.showHeader && !this.socketEvent) {
          this.socketConect();
        }
        else if (!this.showHeader) {
          this.socketDestroy();
        }

        if (route && this.router.url != '/notes') {
          this.getNotes(false);
        }

      };
    });

    this.webApi.isShowAckNotification.subscribe((data: any) =>{
      this.isShowNotes = data;
    })
  }


  ngOnInit() {
    this.webApi.userDetail.subscribe((data: any) => { this.updateUserDetails(data) });
    this.webApi.barLoader.subscribe((data: any) => { this.barLoader = data });  

  }

  ngAfterContentInit() {

  }


  updateUserDetails(data: any) {
    if (data) {
      this.ReminderNotification = 0;
      this.freeLeadNotification = 0;
      this.notification = 0;

      this.userDetail = data;
      this.rights = this.userDetail.rights;
      this.popupNotificationTime = this.webApi.userDetail.value.popup_notification_time ? Number(this.webApi.userDetail.value.popup_notification_time) * 60 * 1000 : 5 * 60 * 1000;
      this.getAgentTarget(false);
      this.getNotification({ type: 'ReminderNotification' });
      this.getNotification({ type: 'freeLeadNotification' });
      this.getSamplifyNotification(false);
      this.getAckNotification(false);
    }

  }

  getNotification(dataVar: any) {

    if ((dataVar.type == 'ReminderNotification' && !this.rights.call_reminder_notification) || (dataVar.type == 'freeLeadNotification' && !this.rights.free_lead_notification)) 
    {
      return;
    }

    let self: any = this;
    let dataParam: any = {
      config: apiUrl.of(dataVar.type),
      payload: {},
      barLoader: true
    }



    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          self[dataVar.type] = res.data.total_count;
          this.notification = this.freeLeadNotification + this.ReminderNotification;
          if (dataVar.type == 'freeLeadNotification') 
          {
            //this.getSamplifyNotification(false);
          }
          if (dataVar.type == 'ReminderNotification' && this.ReminderNotification > 0 && !this.webApi.isAdmin) {
            this.reminderleadItem = [];
            res.data.reminder_leads?.forEach((element: any) => {
              let txt = element.lead_id + " schedule on " + this.common.dateFormat(element.reminder_dt, 'dd-MM-yyyy (hh:mm:a)');
              this.reminderleadItem.push(txt);

            });
            let msgTxt: any = "Lead(s) " + this.reminderleadItem.join(', ');
            this.webApi.showToast(msgTxt, { type: 'notification' });

          }
        }
      }
    });

  }


  getSamplifyNotification(dataVar: any) {

    if(!this.rights?.samplify_notification)
    {
      return
    }

    let dataParam: any = {
      config: apiUrl.of('CountSamplifyStatusNotifications'),
      payload: {},
      barLoader: true
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          this.samplifyNotification = res.data.total_count;
          this.notification = Number(this.notification) + Number(this.samplifyNotification);
        }
      }
    });
  }



  socketConect() {

    this.socketDestroy();
    if (this.userDetail) {
      this.webSocket.conectIO();
      this.socketEvent = this.webSocket.getData().subscribe((dataVar: any) => {
        this.onSocketData(dataVar);
      });
      setTimeout(() => {
        this.insertSocketId('insert');
      }, 3000);
    }

  }

  socketDestroy() {
    if (this.userDetail) {
      this.insertSocketId('delete');
    }

    this.webSocket?.disconnect();
    if (this.socketEvent) {
      this.socketEvent.unsubscribe();
    }
    this.socketEvent = false;
    this.webSocket?.socketData.next(false);
  }


  onSocketData(dataVar: any) {

    if (dataVar?.target == "joinRoom") {

      let userToken = this.common.getTokenId("");

      let usrData = { target: dataVar.target, dataParam: { room: 1, userName: this.userDetail.name, userCode: userToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'], userRole: this.userDetail.role_name } }
      this.webSocket.emitData(usrData);
    }
    else if (dataVar?.target == "leadRowStatus" || dataVar?.target == "leadAssigned" || dataVar?.target == "roundRobin" || dataVar?.target == "reminderORcancel" || dataVar?.target == "bookingLead") {
      this.webSocket.forLeadPage.next(dataVar);
    }
    else if (dataVar?.target == 'ReminderNotification') {
      this.getNotification({ type: 'ReminderNotification' });
    }
    else if (dataVar?.target == 'freeLeadNotification') {
      this.getNotification({ type: 'freeLeadNotification' });
    }
    else if (dataVar?.target == 'samplifyNotification') 
    {
      this.getSamplifyNotification(false);
    }
    else if (dataVar?.targetEvent == 'agentEvent') {
      this.webSocket.forAgentPage.next(dataVar);
    }
    else if (dataVar?.target == 'appReload') {
      this.openPopup(dataVar, 'reloadApp');

    }


  }



  appReload(dataVar: any) {
    location.reload();
  }



  breadCrucmb(dataVar: any) {
    this.breadCrucmbList = [];
    let list = dataVar.split('/');

    let uData = localStorage.getItem("userDetail");
    let mnu = JSON.parse(uData || '')?.appMenu;


    for (let i = 0; i < list.length; i++) {
      let brName = "";
      for (let j = 0; j < mnu.length; j++) {
        if (mnu[j].url == list[i]) {
          brName = mnu[j].label
        }

      }

      let item = { name: brName || list[i], url: '/' + list[i] }
      this.breadCrucmbList.push(item);
    }

  }

  goToProfile() {

  }

  getAgentTarget(dataVar: any) {
    let dataParam: any = {
      config: apiUrl.of('getAgentTarget'),
      payload: { page_size: 10, page_index: 1 }
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

        if (res.is_success) {

          this.agentTarget = res.data.agent_target_list[0];
          // this.agentTarget.next(this.agentTarget);
          this.webApi.setUserTarget(this.agentTarget);
        }
      }
    });
  }

  getNotes(dataVar: any) {
    if (this.webApi.isAdmin || !this.userDetail) {
      return
    }

    // let dataParam: any = {
    //   config: apiUrl.of('GetAgentKnowledgeCenter'),
    //   payload: {},
    //   barLoader: true
    // }
    // this.webApi.apiResponse(dataParam).subscribe({
    //   next: res => {
    //     if (res.is_success) {
    //       this.notes = res.data;
    //       this.webApi.setUserNotes(this.notes)
    //       const result = this.notes?.find((elt: any) => elt.knowledge_center?.is_read == 'False');
    //       if (result && this.router.url != '/notes') {
    //         this.notesRuning = 1;
    //         this.openPopup({}, 'notes');
    //       }
    //     }
    //   },
    //   error: error => {
    //     //console.log(error);
    //   }
    // });

    let dataParam: any = {
      config: apiUrl.of('GetAgentKnowledgeCenterDashboardCount'),
      payload: {},
      barLoader: true
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          if(res?.data?.total_count > 0)
          this.isShowNotes = true;
        }
      },
      error: error => {
        //console.log(error);
      }
    });
  }

  openPopup(dataVar: any, type: string) {
    let initialState: any =
    {
      initialState: {
        title: "",
        isUpdate: dataVar,
        returnData: '',
        parentData: dataVar
      },
      class: type ? 'modal-md' : 'modal-md'
    };
    let bsModalRef: any
    if (type == 'pending-lead') {
      initialState.ignoreBackdropClick = true,
        initialState.initialState.title = 'pending-lead';
      bsModalRef = this.modalService.show(PendingLeadAlertComponent, initialState);
    } else if (type == 'pending-alert') {
      this.isActivePop = false;
      initialState.ignoreBackdropClick = true,
        initialState.initialState.title = 'pending-alert';
      bsModalRef = this.modalService.show(PendingLeadAlertComponent, initialState);
    } else if (type == 'notes') {
      this.isActivePop = false;
      initialState.initialState.title = 'notes';
      initialState.initialState.ackNote = dataVar;
      initialState.initialState.ackId = dataVar?.knowledge_center?.kid;
      initialState.backdrop = 'static'
      initialState.class = 'modal-xl'
      bsModalRef = this.modalService.show(NotesComponent, initialState);
    } else if (type == 'reminder-alert') {
      initialState.initialState.title = 'Call Reminder';
      initialState.initialState.msg = `You have ${dataVar} call reminder notification`;
      initialState.backdrop = 'static';
      initialState.class = 'modal-sm';
      bsModalRef = this.modalService.show(AlertComponent, initialState);
    }
    else if (type == 'reloadApp') {
      initialState.initialState.title = 'Reload App';
      initialState.initialState.msg = "New version uploaded. Do you want to reload app.";
      initialState.backdrop = 'static';
      initialState.class = 'modal-mb';
      bsModalRef = this.modalService.show(ConfirmComponent, initialState);

    }
    else {
      initialState.initialState.title = 'User free lead'
      bsModalRef = this.modalService.show(LeadsFreeUsersComponent, initialState);
    }

    bsModalRef?.onHidden?.subscribe((resData: any) => { this.popUpClosed(bsModalRef) });
  }

  popUpClosed(dataVar: any) {
    this.isActivePop = true;
    if (dataVar.content.title == "User free lead") {
      this.webSocket.emitData({ target: "freeLeadNotification", dataParam: {} });
    } else if (dataVar.content.returnData?.logout) {
      this.logout();
    }
    else if (dataVar.content.returnData?.confirm) {
      this.appReload(true);
    }
    if (!dataVar.content.returnData?.logout) {
      clearTimeout(this.userActivity);
      this.setInActive();
    }
  }

  logout() {
    let dataParam: any = {
      config: apiUrl.of('authLogout'),
      payload: {}
    }

    this.webApi.ameyoLogOut.next(true);


    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

        if (res.is_success) {
          this.insertSocketId('delete');
          localStorage.clear();
          sessionStorage.clear();
          clearTimeout(this.userActivity);
          this.router.navigate(['']);
          this.modalService.hide();
        }


      },
      error: error => {
        //console.log(error);

      }
    });



  }

  getPendindLeadStatus(dataValue: string) {
    let dataParam: any = {
      config: apiUrl.of('pendingLeads'),
      payload: {},
      barLoader: true
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          if (res.data?.actions?.is_ingaged || res.data?.actions?.is_reminder) {
            this.openPopup(res.data, dataValue)
          } else if (dataValue == 'pending-lead') {
            this.logout();
          }
        }
      },
      error: error => {
        //console.log(error);
      }
    });
  }

  sideMenuCallBack(evt: any) {
    this.closeSideNav = evt;
  }

  @HostListener('window:storage', ["$event"])
  onStorageChange(evt: any) {

    if (evt.key == "accessToken") {
      //localStorage.clear();
      //this.router.navigate(["/"]);
    }


  }



  readReminderNotification() {

    if (!this.webApi.isAdmin && this.reminderleadItem.length > 0) {
      let msgTxt: any = "Lead(s) " + this.reminderleadItem.join(', ');
      this.webApi.showToast(msgTxt, { type: 'notification' });
    }


    let dataParam: any = {
      config: apiUrl.of('readReminderNotification'),
      payload: {}
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {

          this.common.currentTab = 'getReminderLead';
          // this.router.navigate(['/leads']);
          this.common.currentLeadStaus = 70;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/leads'])
          });

        }
      }
    });
  }

  setInActive() {
    this.userActivity = setTimeout(() => this.getPendindLeadStatus('pending-alert'), this.popupNotificationTime);
  }

  @HostListener('window:mousemove') refreshUserState() {
    let userToken = this.common.getTokenId("");
    if (this.isActivePop) {
      if (userToken) {
        clearTimeout(this.userActivity);
        this.setInActive();
      }
    }
  }





  getWorkType(dataVar: any) {

    let dataParam: any = {
      config: apiUrl.of(dataVar),
      payload: {},
      barLoader: true
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.status) {
          this.is_call_active = res.workTypes[0].is_call_active;
          this.is_email_active = res.workTypes[0].is_email_active;
          this.is_socialmedia_active = res.workTypes[0].is_socialmedia_active;
          this.is_surbochat_active = res.workTypes[0].is_surbochat_active;
          this.is_ticket_active = res.workTypes[0].is_ticket_active;
          this.is_Manual_Task = res.workTypes[0].is_Manual_Task
        }
        else {
        }
      },
      error: error => {
        //console.log(error);

      }
    });

  }



  updateWorkType(workType: any,action:any) {

    let dataVar: any = "updateSingleWorkType"
    let dataParam: any = {
      config: {
        url: apiUrl.of(dataVar).url,
        method: apiUrl.of(dataVar).method
      },
      payload: {

        "user_id": 0,
        "activityType": workType,
        "activityAction": action == true ? 0 : 1  
        // "user_id": 0,
        // "department_id": 0,
        // "is_call_active": workType == 'is_call_active' ? !this.is_call_active : this.is_call_active,
        // "is_email_active": workType == 'is_email_active' ? !this.is_email_active : this.is_email_active,
        // "is_surbochat_active": workType == 'is_surbochat_active' ? !this.is_surbochat_active : this.is_surbochat_active,
        // "is_socialmedia_active": workType == 'is_socialmedia_active' ? !this.is_socialmedia_active : this.is_socialmedia_active,
        // "is_ticket_active": workType == 'is_ticket_active' ? !this.is_ticket_active : this.is_ticket_active,
        // "is_Manual_Task": workType == 'is_Manual_Task' ? !this.is_Manual_Task : this.is_Manual_Task,
        // "is_active": true

      },
      barLoader: true
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

      },
      error: error => {
        //console.log(error);

      }
    });
  }

  insertSocketId(dataVar: any) {
    let userToken = this.common.getTokenId("");
    let usrData = { roomid: '1', username: this.userDetail?.name, usercode: userToken ? userToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'] : '', userrole: this.userDetail?.role_name, socketid: this.common?.socketId, key: "kinR5cCI6IkpXVCJ9eyJodHRwOi8vc2NoZW1hcy5taWNy345rt" }

    let dataParam: any = {
      config: dataVar == 'insert' ? apiUrl.of('insertSocketId') : apiUrl.of('deleteSocketId'),
      payload: usrData
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
        }
      }
    });
  }


  // tabWasClosed: boolean | undefined;

  // @HostListener('window:beforeunload', ['$event'])
  // doBeforeUnload() {
  //   if (document.visibilityState === 'hidden') {
  //     this.tabWasClosed = true;
  //   }
  //   return false;
  // }

  // @HostListener('window:unload', ['$event'])
  // doUnload() {
  //   debugger
  //   if (this.tabWasClosed) {
  //     this.logout();
  //   }
  // }


  goToNotificationPage(dataVal: boolean) {
    this.common.notificationTab = dataVal ? 'AllFreeUsersDetails' : 'samplify';
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/notification-management']);
    });
  }


  getAckNotification(dataVal: boolean){
    let dataParam: any = {
      config: apiUrl.of('GetAgentKnowledgeCenterDashboard'),
      payload: {},
      barLoader: true
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          this.notes = res.data;
          const result = this.notes?.find((elt: any) => !elt.knowledge_center?.is_read);
          let message = result?.knowledge_center?.header
          this.notes['msg'] = message ? message?.substring(0, 30) : '';
          if (dataVal && result && this.router.url != '/notes') {
            this.openPopup(result, 'notes');
          }
        }
      },
      error: error => {
      }
    });
  }

  getAck() {
    this.getAckNotification(true);
  }

}
