<div class="appNav bottomShadow fixed-top" [ngClass]="{'borderLoader':barLoader,'bg-primary': !currentEnv.production}"
  *ngIf="showHeader">

  <div class="noticeDiv" *ngIf="rights.target_for_agent">

    <div class="d-flex align-items-center justify-content-between">
      <div>
        <p>Monthly Target: <span class="fw-bold">{{agentTarget?.month_target_value ? agentTarget?.month_target_value :
            0}}</span></p>
        <p>Achived: <span class="fw-bold">{{agentTarget?.achieved_monthly_target_amt ?
            agentTarget?.achieved_monthly_target_amt : 0}}</span></p>
      </div>

      <div>
        <p>Daily Target: <span class="fw-bold">{{agentTarget?.daily_target_value ? agentTarget?.daily_target_value :
            0}}</span></p>
        <p class="text-end">Achived: <span class="fw-bold">{{agentTarget?.achieved_daily_target_amt ?
            agentTarget?.achieved_daily_target_amt : 0}}</span></p>
      </div>
    </div>
  </div>


  <div class="menuBtn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDiv">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
      viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
    </svg>
  </div>
  <div class="topbarLogo"><img src="assets/images/logo.png" /></div>

  <div class="userMenu">
    <!-- *ngIf="this.notes && this.router.url != '/notes'" -->
    <div class="fs-5 custum-marque" *ngIf="isShowNotes && this.router.url != '/notes'" (click)="getAck()">
      <!-- <i class="bi bi-envelope" style="font-size: 25px;" title="notes" (click)="getAck()"></i> -->
      <!-- <p>You have one note</p>     -->
      <marquee width="90%" class="text-red" *ngIf="!this.webApi.isAdmin">Notification: <span class="fw-bold">{{notes?.msg}}</span></marquee>
    </div>

    <div class="notification">
      <div *ngIf="!notification" class="d-flex align-items-center p-2 position-relative">
        <i class="bi bi-bell" style="font-size: 25px;"></i>

      </div>

      <div *ngIf="notification" class="d-flex align-items-center p-2 position-relative" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="bi bi-bell text-red" style="font-size: 25px;"></i>
        <div class="customBadge bg-danger text-dark">{{notification}}</div>
      </div>

      <ul class="dropdown-menu dropdown-menu-end w-auto shadowSm1">
        <li class="list-group-item list-group-item-action cursorPointer p-2"
          *ngIf="ReminderNotification && rights?.call_reminder_notification" (click)="readReminderNotification()">Call
          Reminder: <span class="fw-bold">{{ReminderNotification}}</span></li>

        <!-- <li class="list-group-item list-group-item-action cursorPointer fw-bold p-2"
          *ngIf="freeLeadNotification && rights?.free_lead_notification" (click)="openPopup('', '')">User free lead:
          <span class="fw-bold">{{freeLeadNotification}}</span>
        </li> -->
        <li class="list-group-item list-group-item-action cursorPointer fw-bold p-2"
          *ngIf="freeLeadNotification && rights?.free_lead_notification" (click)="goToNotificationPage(true)">User free lead:
          <span class="fw-bold">{{freeLeadNotification}}</span>
        </li>
        <li class="list-group-item list-group-item-action cursorPointer fw-bold p-2"
          *ngIf="samplifyNotification && rights?.samplify_notification" (click)="goToNotificationPage(false)">Samplify :
          <span class="fw-bold">{{samplifyNotification}}</span>
        </li>
      </ul>

    </div>


    <div class="btn-group bg-transparent"
      *ngIf="this.userDetail?.role_name=='Admin IB' || this.userDetail?.role_name=='Team IB'">
      <div data-bs-toggle="dropdown" aria-expanded="false">
        <button (click)="getWorkType('getWorkType')" class="btn btn-primary btn-white shadow-sm btnworktype">Work Type
          <i class="ms-3 bi bi-chevron-down"></i></button>
      </div>
      <div class="dropdown-menu dropdown-menu-end worktypeMenu ">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" (click)="updateWorkType(1,is_call_active)"
            [(ngModel)]="is_call_active">
          <label class="form-check-label" for="flexSwitchCheckChecked">Call</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" (click)="updateWorkType(2,is_email_active)"
            [(ngModel)]="is_email_active">
          <label class="form-check-label" for="flexSwitchCheckChecked">Email</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" (click)="updateWorkType(6,is_ticket_active)"
            [(ngModel)]="is_ticket_active">
          <label class="form-check-label" for="flexSwitchCheckChecked">Ticket</label>
        </div>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" (click)="updateWorkType(4,is_Manual_Task)"
            [(ngModel)]="is_Manual_Task">
          <label class="form-check-label" for="flexSwitchCheckChecked">Manual Task</label>
        </div>

      </div>
    </div>



    <div class="btn-group">
      <div class="d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="bi bi-person-circle text-secondary" style="font-size: 25px;"></i>
        <div title="{{userDetail?.name}}">{{userDetail?.name}} <br>
          <span title="{{userDetail?.role_name}}">&#8212; {{userDetail?.role_name}}</span>
        </div>
      </div>
      <ul class="dropdown-menu dropdown-menu-end w-100 shadowSm1">
        <li class="list-group-item list-group-item-action cursorPointer" (click)="goToProfile()"><i
            class="bi bi-person-video3"></i> Profile</li>

        <li class="list-group-item list-group-item-action cursorPointer fw-bold text-red"
          (click)="getPendindLeadStatus('pending-lead')"><i class="bi bi-box-arrow-in-right"></i> Logout</li>
      </ul>
    </div>
  </div>




</div>



<app-sidenav (returnCallBack)="sideMenuCallBack($event)"></app-sidenav>