import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from "src/app/guard/authguard.guard";


const routes: Routes = [

  {
    path: "",
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },

  {
    path: "dashboard",
    canActivate: [AuthguardGuard],

    loadChildren: () => import('./modules/home-page/home-page.module').then(m => m.HomePageModule)

  },
  {
    path: "role-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/role-management/role-management.module').then(m => m.RoleManagementModule)
  },
  {
    path: "menu-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/menu-management/menu-management.module').then(m => m.MenuManagementModule)
  },
  {
    path: "action-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/action-management/action-management.module').then(m => m.ActionManagementModule)
  },
  {
    path: "user-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  {
    path: "leads",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lead-management/lead-management.module').then(m => m.LeadManagementModule)
  },
  {
    path: "booking",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/booking-management/booking-management.module').then(m => m.BookingManagementModule)
  },
  {
    path: "cluster-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/cluster-management/cluster-management.module').then(m => m.ClusterManagementModule)
  },
  {
    path: "company-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/company-management/company-management.module').then(m => m.CompanyManagementModule)
  },
  {
    path: "lead-source-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lead-source-management/lead-source-management.module').then(m => m.LeadSourceManagementModule)
  },
  {
    path: "lead-medium-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lead-medium-management/lead-medium-management.module').then(m => m.LeadMediumManagementModule)
  },
  {
    path: "lead-campaign-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lead-campaign-management/lead-campaign-management.module').then(m => m.LeadCampaignManagementModule)
  },
  {
    path: "lead-check-list-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lead-check-list-management/lead-check-list-management.module').then(m => m.LeadCheckListManagementModule)
  },
  {
    path: "lookup-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lookup-management/lookup-management.module').then(m => m.LookupManagementModule)
  },
  {
    path: "department-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/department-management/department-management.module').then(m => m.DepartmentManagementModule)
  },
  {
    path: "designation-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/designation-management/designation-management.module').then(m => m.DesignationManagementModule)
  },
  {
    path: "active-agent",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/active-agent/active-agent.module').then(m => m.ActiveAgentModule)
  },
  {
    path: "agent-target",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/agent-target/agent-target.module').then(m => m.AgentTargetModule)
  },
  {
    path: "payment",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/payment-gateway/payment-gateway.module').then(m => m.PaymentGatewayModule)
  },
  {
    path: "acknowledgement",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/acknowledgement/acknowledgement.module').then(m => m.AcknowledgementModule)
  },
  {
    path: "activity",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/attempted-free-lead/attempted-free-lead.module').then(m => m.AttemptedFreeLeadModule)
  },
  {
    path: "notes",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/notes/notes.module').then(m => m.NotesModule)
  },
  {
    path: "interaction-lead",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/interaction-lead/interaction-lead.module').then(m => m.InteractionLeadModule)
  },
  {
    path: "tickets",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/ticket-management/ticket-management.module').then(m => m.TicketManagementModule)
  },
  {
    path: "bulk-email",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/bulk-email-management/bulk-email-management.module').then(m => m.BulkEmailManagementModule)
  },
  {
    path: "reports",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/report-management/report-management.module').then(m => m.ReportManagementModule)
  },
  {
    path: "client-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/client-management/client-management.module').then(m => m.ClientManagementModule)
  },
  {
    path: "sales-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/sales-management/sales-management.module').then(m => m.SalesManagementModule)
  },
  {
    path: "mis-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/mis-management/mis-management.module').then(m => m.MisManagementModule)
  },
  {
    path: "config-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/config-management/config-management.module').then(m => m.ConfigManagementModule)
  },
  {
    path: "ameyo-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/ameyo-management/ameyo-management.module').then(m => m.AmeyoManagementModule)
  },
  {
    path: "chat-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/chat-management/chat-management.module').then(m => m.ChatManagementModule)
  },
  {
    path: "notification-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/notification-management/notification-management.module').then(m => m.NotificationManagementModule)
  },
  {
    path: "agent-summary",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/agent-summary/agent-summary.module').then(m => m.AgentSummaryModule)
  },
  {
    path: "lims-utility",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/lims-utility/lims-utility.module').then(m => m.LimsUtilityModule)
  },
  {
    path: "category-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/category-management/category-management.module').then(m => m.CategoryManagementModule)
  },
  {
    path: "inbound-department-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/inbound-department-management/inbound-department-management.module').then(m => m.InboundDepartmentManagementModule)
  },
  {
    path: "agent-lead-management",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/agent-preference/agent-preference.module').then(m=>m.AgentPreferenceModule)
  },
  {
    path: "escalation-list",
    canActivate: [AuthguardGuard],
    loadChildren: () => import('./modules/inbound/escalation-list-management/escalation-list-management.module').then(m=>m.EscalationListManagementModule)
  },
  {
    path: "**",
    redirectTo: '',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
