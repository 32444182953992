<div class="modal-header bg-secondary">
    <div class="heading3 before1">{{title}}</div>
    <button type="button" class="close btn-close pull-right" aria-label="Close" hidden>
        <span aria-hidden="true" class="visually-hidden text-white">&times;</span>
    </button>
    <div class="closeBtn" (click)="modalRef.hide()"><i class="bi bi-x-square"></i></div>
</div>
<div class="modal-body p-0">
    <div class="shadowSm1 custum-overflow">
        <div class="customTabel text-break">
            <ng-container *ngFor="let item of leadsfreeUsers; let rowN = index; let lastN = last;">
                <div class="listRow">
                    <div>
                        <div>{{item.info}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div>