
    <div class="alertPopup">


    <div class="modal-header">
        <h5 class="modal-title pull-left"><i class="bi bi-exclamation-triangle"></i> {{title || 'Alert'}}</h5>
        <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{msg}}
    </div>

</div>  
