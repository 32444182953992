import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertComponent } from 'src/app/shared/component/popup/alert/alert.component'
import {WebapiService} from "src/app/services/webapi.service";
import { apiUrl } from "src/environments/config";
import { Router, ActivatedRoute } from '@angular/router';
import { UpdateCustomerComponent} from 'src/app/shared/customer-profile/update-customer/update-customer.component';

declare var ameyo: any;
declare var iframeCustom:any;

@Component({
  selector: 'app-ameyo-calling',
  templateUrl:'./ameyo-calling.component.html',
  styleUrls: ['./ameyo-calling.component.scss']
})
export class AmeyoCallingComponent implements OnInit, AfterViewInit {

  static selfObj:any;

  iW: any = '100%';
  iH: any = '100%';
  ameyoOn: any = false;
  customIntegration: any = {};
  isAmeyoUI:any;
  isloggedIn:any;
  ameyoLeft:any = false;
  currentCall:any;
  ameyoCallReady:any;
  customerPopUpShow:any;
 

  ameyoBaseUrl:any = "https://app3.ameyoemerge.in:8443/";

  @ViewChild('iframeDiv') iframeDiv: any = ElementRef;

  constructor(private webApi:WebapiService, public modalService: BsModalService, private router:Router) {
    AmeyoCallingComponent.selfObj = this;
   }



  ngOnInit(): void 
  {

    this.webApi.ameyoCall.subscribe((data:any)=>this.onAmeyoCall(data));
    this.webApi.ameyoLogin.subscribe((data:any)=>this.loginInAmeyo(data));
    this.webApi.ameyoLogOut.subscribe((data:any)=>this.loginOutAmeyo(data));
  }


  ngAfterViewInit(): void 
  {

    this.ameyoIframe();
   

  }


  loginInAmeyo(dataVar:any)
  {
    if((!ameyo?.integration && !this.isAmeyoUI) || !dataVar)
    {
      //let self = this;
      //setTimeout(()=>self.ameyoSetup,10000);
      return;
    }

    ameyo.integration.doLogin(dataVar.user,dataVar.pass);

  }

  loginOutAmeyo(dataVar:any)
  {
    if((!ameyo?.integration && !this.isAmeyoUI) || !dataVar)
    {
      return;
    }

    ameyo.integration.doLogout();

  }


  setUiPos(dataVar:any)
  {
    this.ameyoLeft = dataVar;
    this.webApi.ameyoLeftPos.next(this.ameyoLeft);
  }
  openAmeyoFrame()
  {
    this.ameyoOn = false;
    this.setUiPos(this.ameyoOn);
  }


  onAmeyoCall(dataVar:any)
  {
    
    if(dataVar)
    {
      this.ameyoOn = true;
      ameyo.integration.doDial(dataVar.number, dataVar.user,{});
      //ameyo.integration.doDial('9968427808','crm001',{});
    }

  }


  testFun(dataVar:any)
  {
    
    this.callInteraction({
      "queueId": "134",
      "dstPhone": "918045305223",
      "campaignId": "167",
      "associationType": "inbound.dial.association",
      "sessionId": "d688-654bd913-ses-CRM001-xHPm7SIKrhPpuFpo7BPBTOZEmhBPKZq61aoYyRVJNsp2MgL6EWFwykhPGWJpM3VaXk92yFIxLUEXOCovSfbhGndffhQ6XA0joHQlKf2hgJGrKt1M8KQY-21108",
      "userId": "CRM001",
      "callType": "inbound.call.dial",
      "crm_push_generated_time": "1700455692558",
      "userCrtObjectId": "CRM001-f8a304a1-184e-4b14-a6c6-62b483f14f21-uce-webrtc_CRM001@-18385",
      "queueName": "test",
      "phone": "9090909090",
      "disableDispositionOnActiveCall": "false",
      "crtObjectId": "d688-654bd913-vce-daf-162694",
      "isOrignalPhoneRequiredInCRM": "true",
      "displayPhone": "9968427808"
    });
    

    //this.openPopup('customerPopUp',{profile_id:3983});
    //iframeCustom.setCSS();
  }




  ///////////////////////////////////////////////////////////
  ameyoIframe()
  {
    let crmOrigin = window.location.href;
    let iframeUrl = this.ameyoBaseUrl + "ameyowebaccess/toolbarLogin-vl.htm?";
    iframeUrl = iframeUrl + "origin=" + crmOrigin;

    let iframeHtml = '<iframe allow = "microphone"  height="' + this.iH + '" width="' + this.iW + '" id="ameyoIframe" src="' + iframeUrl + '"></iframe>';
    this.iframeDiv.nativeElement.innerHTML = iframeHtml;

    this.ameyoSetup();
    this.hideIframeConsole();

  }


  hideIframeConsole()
  {
    let iframe:any = document.getElementById('ameyoIframe');
   
    let iframeWindow:any = iframe.contentWindow;
    iframeWindow.console.log = function() { /* nop */ };
  }

  
  initializeCRMUI(...dataVar:any)
  {
    AmeyoCallingComponent.selfObj.isAmeyoUI = true;
    
  }

  customShowCrm(...dataVar:any)
  {
    
    let callDetail = JSON.parse(dataVar?.[1]) || {}; 
   
    
    if(callDetail.callType?.indexOf('inbound') > -1  && AmeyoCallingComponent.selfObj.currentCall?.crtObjectId != callDetail.crtObjectId)
    {
      AmeyoCallingComponent.selfObj.ameyoOn = true;
      AmeyoCallingComponent.selfObj.ameyoLeft = true;
      AmeyoCallingComponent.selfObj.callInteraction(callDetail);
    }
    else
    {
      console.log("outCall");
    }
    
  }

  handleOnLoad(...dataVar:any)
  {
    console.log("lod",dataVar)
  }

  handleLoginStatus(...dataVar:any)
  {
    AmeyoCallingComponent.selfObj.isloggedIn = dataVar?.[0];
    console.log("logStatus",dataVar)
    
  }
  handleLogout(...dataVar:any)
  {
    
    AmeyoCallingComponent.selfObj.isloggedIn = false;
    AmeyoCallingComponent.selfObj.ameyoCallReady = false;
  }

  handleLogin(...dataVar:any)
  {
    
    console.log("onLogin",dataVar)
  }


  handleAutoCallOn(...dataVar:any)
  {
    
    AmeyoCallingComponent.selfObj.ameyoCallReady = true;
  }
  handleAutoCallOff(...dataVar:any)
  {
    AmeyoCallingComponent.selfObj.ameyoCallReady = false;
  }


  handleReady(...dataVar:any)
  {
    AmeyoCallingComponent.selfObj.ameyoCallReady = true;
    
  }
  handleBreak(...dataVar:any)
  {
    AmeyoCallingComponent.selfObj.ameyoCallReady = false;
    
  }
  handleHangup(...dataVar:any)
  {
    
    console.log("handleHangup",dataVar)
  }
  
  

  


  

  


  callInteraction(dataVar:any)
  {
      let postParam = {
        "channelType": dataVar.callType,
        "channel": dataVar.dstPhone,
        "subject": dataVar.queueName, 
        "short_description": "",
        "name": '', 
        "email": "",
        "phone_number": dataVar.phone
      }
      let dataParam: any = {
        config: apiUrl.of('createInt'),
        payload: postParam,
        barLoader: true
      }
  
      this.webApi.apiResponse(dataParam).subscribe({
        next: res => {

          //if (res.interactionid && (this.router.url).indexOf('interaction-lead') == -1)
          if(!this.customerPopUpShow)
          {

            this.openPopup('customerPopUp',{profile_id:res.profile_id, interaction_id: res.interactionid});

            
            //let param: string[] = ['/interaction-lead',res.interactionid,{isAmeyoCall: true }]; 
            //this.router.navigate(param);
          }
        }
      });
  
    
  }


  openPopup(type: any, item: any) {

    let compTemplate: any = AlertComponent;

    let initState: any =
    {
      initialState: {
        title: "Alert",
        isUpdate: false,
        returnData: false,
        parentData: false

      },
      class: 'modal-lg'

    };


    if (type == 'alert') 
    {
      initState.initialState.msg = "Incomming call from "+item.mobile; 
      initState.initialState.title= 'Alert'
    }
    else if(type == 'customerPopUp')
    {
      initState.initialState.title = "Update Customer Details";
      initState.initialState.isUpdate = true;
      initState.initialState.profile_id = item.profile_id;
      initState.initialState.interaction_id = item.interaction_id;
     
      compTemplate =  UpdateCustomerComponent;  

      
    }

    let bsModalRef: any = this.modalService.show(compTemplate, initState);
    bsModalRef.onHidden?.subscribe((resData: any) => { this.popUpClosed(bsModalRef) });

    
   
  }


 



  popUpClosed(dataVar: any) 
  {
    console.log(dataVar)
    if (dataVar.content.returnData) 
    {
     
    }

  }







  ameyoSetup() {

    if(!ameyo?.integration)
    {
      let self = this;
      setTimeout(()=>self.ameyoSetup,10000);
      return;
    }

    ameyo.integration.setBaseUrl(this.ameyoBaseUrl);
    

    this.customIntegration.intializeUI = this.initializeCRMUI;
    this.customIntegration.onLoadHandler = this.handleOnLoad;
    this.customIntegration.loginStatusHandler = this.handleLoginStatus;
    this.customIntegration.showCrm = this.customShowCrm;
    this.customIntegration.loginHandler = this.handleLogin;
    this.customIntegration.logoutHandler = this.handleLogout;

    this.customIntegration.autoCallOnHandler = this.handleAutoCallOn;
    this.customIntegration.autoCallOffHandler = this.handleAutoCallOff;
    this.customIntegration.readyHandler = this.handleReady;
    this.customIntegration.breakHandler = this.handleBreak;
    this.customIntegration.hangupHandler = this.handleHangup;
    



   /* 
    
    this.customIntegration.showCrmDetailed = showCrmDetailedCustom;
    this.customIntegration.forceLoginHandler = handleForceLogin;
   
    this.customIntegration.failedDispositionNotifyHandler = handleFailedDispositionNotify;
    this.customIntegration.getDispositionCodesHandler = handleDispositionList;
    
    
    this.customIntegration.selectExtensionHandler = handleSelectExtension;
    this.customIntegration.modifyExtensionHandler = handleModifyExtension;
    this.customIntegration.selectCampaignHandler = handleSelectCampaign;
    
    this.customIntegration.transferToPhoneHandler = handleTransferToPhone;
    this.customIntegration.transferInCallHandler = handleTransferInCall;
    this.customIntegration.transferToAQHandler = handleTransferToAQ;
    this.customIntegration.transferToIVRHandler = handleTransferToIVR;
    this.customIntegration.transferToUserHandler = handleTransferToUser;
    this.customIntegration.transferToCampaignHandler = handleTransferToCampaign;
    this.customIntegration.conferWithPhoneHandler = handleConferWithPhone;
    this.customIntegration.conferWithTPVHandler = handleConferWithTPV;
    this.customIntegration.conferWithUserHandler = handleConferWithUser;
    this.customIntegration.conferWithLocalIVRHandler = handleConferWithLocalIVR;
    this.customIntegration.disposeCall = customCallDispose;
    this.customIntegration.disposeAndDialHandler = handleDisposeAndDial;
    */



    
    ameyo.integration.registerCustomFunction("intializeUI", this.customIntegration);
    ameyo.integration.registerCustomFunction("onLoadHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("loginStatusHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("showCrm", this.customIntegration);
    ameyo.integration.registerCustomFunction("loginHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("logoutHandler", this.customIntegration);

    ameyo.integration.registerCustomFunction("autoCallOnHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("autoCallOffHandler",this.customIntegration);
    ameyo.integration.registerCustomFunction("readyHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("breakHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("hangupHandler", this.customIntegration);


    /*
   
    
    
    ameyo.integration.registerCustomFunction("failedDispositionNotifyHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("getDispositionCodesHandler",
      this.customIntegration);
    
    ameyo.integration.registerCustomFunction("loginStatusHandler",
      this.customIntegration);
    ameyo.integration
      .registerCustomFunction("forceLoginHandler", this.customIntegration);
    ameyo.integration.registerCustomFunction("selectExtensionHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("modifyExtensionHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("selectCampaignHandler",
      this.customIntegration);
   
    
    ameyo.integration.registerCustomFunction("transferToPhoneHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("transferInCallHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("transferToAQHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("transferToIVRHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("transferToUserHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("transferToCampaignHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("conferWithPhoneHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("conferWithTPVHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("conferWithUserHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("conferWithLocalIVRHandler",
      this.customIntegration);
    ameyo.integration.registerCustomFunction("disposeCall", this.customIntegration);
    ameyo.integration.registerCustomFunction("showCrmDetailed", this.customIntegration);
    ameyo.integration.registerCustomFunction("disposeAndDialHandler",
      this.customIntegration);
    
    */

  }


}
