<div class="mainContainer" [ngClass]="{'ameyoLeftFix':ameyoLeft}">
  
  <div class="reloadBtn" hidden>
    <button (click)="reloadSite()" class="btn btn-outline-secondary rounded">Reload {{hasUpdate?'on':'off'}}</button>
  </div>

  <app-header></app-header>
  

  <router-outlet></router-outlet>


  <app-footer></app-footer>
  <app-toasts-container></app-toasts-container>
  <app-loader></app-loader>

  
  

</div>

