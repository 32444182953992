import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
import { WebapiService } from 'src/app/services/webapi.service';
import { apiUrl } from 'src/environments/config';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent {
  notes: any;
  message: any;
  currentIndex: any = 0;
  @Input() title: any;
  @Input() ackNote: any;

  constructor(private webApi: WebapiService, public common: CommonService, public modalService: BsModalService, private router: Router) {
    this.webApi.userNotes.subscribe(data => {
      this.notes = data;
      if (this.notes && this.notes.length > 0) {
        this.getNotesMessage(this.notes[0], 0)
      }

    })
  }

  ngOnInit(): void {
    this.webApi.emitUserDetail();
    if(this.ackNote){
      this.notes = [];
      this.notes.push(this.ackNote);
      this.getNotesMessage(this.notes[0], 0);
    } else {
      this.getNotes(false);
    }
    
  }

  getNotes(dataVar: any) {
    if (this.router.url != '/notes') {
      return
    }

    let dataParam: any = {
      config: apiUrl.of('GetAgentKnowledgeCenter'),
      payload: {},
      barLoader: true
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          this.notes = res.data;
          if(this.notes?.length){
            this.getNotesMessage(this.notes[0], 0);
          }          
        }
      },
      error: error => {
        //console.log(error);
      }
    });
  }

  getNotesMessage(dataVal: any, index: any) {
    this.message = dataVal.knowledge_center;
    this.message['prescriptions'] = dataVal.prescription;
    this.currentIndex = index;
  }

  getFileName(dataVal: any) {
    let str = dataVal ? (dataVal).toString() : ''
    return str.split('.')[0];
  }

  getFilePath(dataVar: any, elm: any) {
    let dataParam: any = {
      config: apiUrl.of('downloadPrescription'),
      payload: { fileurl: dataVar.url },
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          dataVar.url = res.data.url;
          dataVar.is_open_path = true;
          setTimeout(() => elm.click(), 100);
        }
      }
    });
  }
}
