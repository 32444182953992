import { Component,OnInit,Input } from '@angular/core';
import { WebapiService } from 'src/app/services/webapi.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})


export class LoaderComponent implements OnInit {

  loaderVal=0;

  constructor(private webApi:WebapiService){}

  ngOnInit()
  {
    this.webApi.mainLoader.subscribe((data:any)=>{this.loaderVal = data});
  }

}
