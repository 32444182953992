import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http'
import { DatePipe } from '@angular/common';

import {OnCreateModule} from "src/app/shared/directives/on-create/on-create.module"

import {HeaderModule} from "src/app/layout/header/header.module";
import {LoaderComponent } from './layout/loader/loader.component';
import { ToastsContainerComponent } from './layout/toasts-container/toasts-container.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LeadCheckListComponent } from './layout/footer/lead-check-list/lead-check-list.component';
import { FiltersModule } from './shared/filters/filters.module';

import {AmeyoCallingModule} from './shared/ameyo-calling/ameyo-calling.module';





@NgModule({
  declarations: [
    AppComponent,
    ToastsContainerComponent,
    FooterComponent,
    LoaderComponent,
    LeadCheckListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OnCreateModule,
    HeaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FiltersModule,
    AmeyoCallingModule
    

    
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
