<div class="ameyoCalling" [ngClass]="{'onAmeyoUI':ameyoOn, 'onAmeyoLogin':(isloggedIn && !ameyoCallReady), 'onAmeyoCallReady':ameyoCallReady}">
    
    <div class="ameyoHeader d-flex align-items-center justify-content-between" [ngClass]="{'withBg':ameyoOn}">

        <div class="callBtn d-flex justify-content-between align-items-center" (click)="ameyoOn = true" *ngIf="!ameyoOn"><i class="bi bi-headset"></i> <span class="px-1 fw-bold text-light"> Ameyo Call</span></div>
        
        <div class="croseBtn text-end" (click)="testFun(true)" hidden >.</div>
        
        <div class="croseBtn text-end" (click)="setUiPos(true)" *ngIf="ameyoOn && !ameyoLeft"><i class="bi bi-layout-sidebar-inset" style="color: var(--color5);"></i></div>
        <div class="croseBtn text-end" style="transform: scaleX(-1);" (click)="setUiPos(false)" *ngIf="ameyoOn && ameyoLeft"><i class="bi bi-pip" style="color: var(--color5);"></i></div>
        <div class="croseBtn text-end" (click)="openAmeyoFrame()" *ngIf="ameyoOn"><i class="bi bi-arrow-down-left-square-fill"></i></div>
    </div>
   

    
    <div class="iframeCSS" #iframeDiv [hidden]="!ameyoOn">

      
    </div>
</div>
