import { Component, OnInit } from '@angular/core';
import { RouteConfigLoadStart, Router, } from '@angular/router';
import { WebapiService } from "./services/webapi.service";
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { rootEnv } from "src/environments/config";

declare const require: (path: string) => any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'CRM Pathkind';
  ameyoLeft: any;
  hasUpdate = false;

  

  constructor(private webApi: WebapiService, private router: Router,  private swUpdate: SwUpdate) {


  }


  ngOnInit(): void {
  /*
     // check for platform update
     if (this.swUpdate.isEnabled)
     {
      interval(15000).subscribe(() => this.swUpdate.checkForUpdate().then(() => {
        // checking for updates
      }));
    }
    this.swUpdate.available.subscribe(() => {
      this.hasUpdate = true;
      window.location.reload()
    });

    */


    
    const APP_VERSION = require('../../package.json').version;
    if (APP_VERSION) {
      (window as any).APP_VERSION = APP_VERSION;
      // for puase socket
      // this.getAppV(false);
    }

    this.webApi.ameyoLeftPos.subscribe((data: any) => this.ameyoLeft = data);
  }



  reloadSite(): void {
    location.reload();
  }


  getAppV(dataVar: any) {
    let dataParam: any = {
      config: {url:rootEnv.config.webSocket+'/socket-restapi', method:'POST'},
      payload: {
        "action":"app_v",
        "key":"kinR5cCI6IkpXVCJ9eyJodHRwOi8vc2NoZW1hcy5taWNy345rt",
        "data":""
      }
    }

    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

        if (res?.data) 
        {
          let crnAppV = res.data.app_v;

          if(crnAppV != localStorage.getItem('app_v'))
          {
            localStorage.setItem('app_v',crnAppV);

            this.reloadSite();
          }
          
        }
      }
    });
  }
 

}
