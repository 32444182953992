<div class="modal-header bg-secondary">
    <div class="heading3 before1">{{title == 'pending-lead' ? 'Pending lead' : 'Pending lead alert'}}</div>
    <button type="button" class="close btn-close pull-right" aria-label="Close" hidden>
        <span aria-hidden="true" class="visually-hidden text-white">&times;</span>
    </button>
    <div class="closeBtn" (click)="closeWithStayLogin()"><i class="bi bi-x-square"></i></div>
</div>

<div class="modal-body shadowSm1">
    <div class="mt-2 mb-4 d-flex justify-content-between align-items-center">
        <div class="mb-4 p-0">
            <p class="m-0 p-0 text-dark">
                <span>You have pending {{parentData?.actions?.is_ingaged ? 'Lead' :''}}</span>
                <span>{{parentData?.actions?.is_reminder ? 'And call reminder' :''}}</span>
                <span>, Do You want to shut down</span>
            </p>

        </div>

    </div>

    <div class="d-flex justify-content-center gap-2 mt-4">
        <button class="btn btn-primary" (click)="closelModalAndLogout()" *ngIf="title == 'pending-lead'">OK</button>
        <button class="btn btn-primary" (click)="closeWithStayLogin()" *ngIf="title == 'pending-alert'">OK</button>
        <button class="btn btn-primary" (click)="closeWithStayLogin()">Cancel</button>
    </div>
</div>