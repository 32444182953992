import { Component, AfterViewInit, OnInit, ViewChild, Output,EventEmitter, Renderer2, ViewContainerRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {menuIcon, apiUrl} from 'src/environments/config';
import { WebapiService } from 'src/app/services/webapi.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
  
})
export class SidenavComponent implements AfterViewInit, OnInit {

  menuListData:any;
  iconClass:any = menuIcon;

  @ViewChild('closeSideNavBtn') closeSideNavBtn:any;
  @ViewChild('menuListDiv',{ static: true }) menuListDiv: any;
  
  

  @Output() returnCallBack: EventEmitter<any> = new EventEmitter();

  constructor(private vref:ViewContainerRef, private rendarElm:Renderer2, private activatedRoute:ActivatedRoute,  private router:Router, private webApi:WebapiService, public common:CommonService){}



  ngOnInit(): void {
    
    
   
  }


 
  ngAfterViewInit() 
  {
    this.returnCallBack.emit(this.closeSideNavBtn);
    this.webApi.userDetail.subscribe((data:any)=>{
      this.menuListData = data.appMenu;
      this.genrateMenuList();
    });
    
  }



  isChildActive(dataVar:any,menutxt:any)
  {
    let mTxt = menutxt.toLowerCase();
    let url =this.router.url.split("/")[1];
    let isChild = dataVar.filter((item:any)=>  (item.module_url).indexOf(mTxt)>-1 && item.module_url ==  url );
    return isChild.length;
  }



  

  genrateMenuList()
  { 
    return;
    if(!this.menuListData)
    {
     
    }

    this.menuListDiv.nativeElement.innerHTML="";

    this.menuListDiv.nativeElement.setAttribute('class','accordion accordion-flush');
    this.menuListDiv.nativeElement.setAttribute('id','rootNav');

    for(let i= 0; i<this.menuListData.length; i++)
    {
      let div = this.rendarElm.createElement('div');
      div.innerHTML = this.accordionItem(this.menuListData[i],"rootNav");
      this.rendarElm.setAttribute(div,'class','accordion-item');
      this.rendarElm.appendChild(this.menuListDiv.nativeElement,div);

    }
    
    
  }



  accordionItem(dataVar:any, parentDiv:any)
  {
     //let item = "<div class='singleMenu' type='button' [routerLink]='["+dataVar.module_url+"]' routerLinkActive='activeMenu' [routerLinkActiveOptions]='{ exact: true }'>" +dataVar.module_name+ "</div>";
     let item = "<div class='singleMenu' type='button' routerLink='"+dataVar.module_url+"' routerLinkActive='activeMenu' routerLinkActiveOptions='{ exact: true }'>" +dataVar.module_name+ "</div>";

    if(dataVar.submenu?.length>0)
    {
      let elmId = "tark"+dataVar.module_name.replace(/ /g, "_");

      item = "<div class='accordion-header'>";
      item+= "<div class='accordion-button collapsed' type='button' data-bs-toggle='collapse' data-bs-target='#" +elmId+ "' aria-expanded='false' aria-controls='flush-collapseOne'>";
      item+= dataVar.module_name;
      item+= "</div></div>";

      item+= "<div id='"+elmId+"' class='accordion-collapse collapse' data-bs-parent='#"+ parentDiv +"'>";
      item+= "<div class='accordion-body'>"+ this.childAccordion(dataVar.submenu, dataVar.module_name) +"</div>";

      item+= "</div>";
  

    }
   
    return item;
  }


  childAccordion(dataVar:any,parentDiv:any)
  {
    let childItem:any = "";
    for(let i= 0; i<dataVar.length; i++)
    {
      let div = this.rendarElm.createElement('div');
      div.innerHTML = this.accordionItem(dataVar[i], parentDiv);
      this.rendarElm.setAttribute(div,'class','accordion-item');

      childItem+= div.outerHTML;

    }

    return childItem;

  }


 


  logout()
  {
    let dataParam:any={
      config: apiUrl.of('authLogout'),
      payload: {}
      }

    this.webApi.ameyoLogOut.next(true);
    
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {

        if(res.is_success)
        {
          localStorage.clear();
          this.router.navigate(['/']);
         
        }
        
       
         
      },
      error: error => {
       
        localStorage.clear();
        this.router.navigate(['/']);
        
      }
      
    });
  }



}
