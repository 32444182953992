import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WebapiService } from 'src/app/services/webapi.service';
import { apiUrl } from 'src/environments/config';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {
  title: any;
  ackId: any;
  ackNote: any;
  constructor(public modalRef: BsModalRef, private webApi: WebapiService) { }


  updateAckReadStatus() {
    let dataParam: any = {
      config: apiUrl.of('UpdateAgentKnowledgeCenterDashboard'),
      payload: { kid: this.ackId },
      barLoader: true
    }
    this.webApi.apiResponse(dataParam).subscribe({
      next: res => {
        if (res.is_success) {
          this.modalRef.hide();
          this.webApi.showToast('', { type: 'success' });
          this.webApi.setAckNotification(false);
        }
      },
      error: error => {
        //console.log(error);
      }
    });
  }
}
