export const environment = {
    production: true,
    config:
    {
    baseUrl:
     {
        _common:'https://master.api.crm.pathkindlabs.com',
        _user:'https://auth.api.crm.pathkindlabs.com',
        _leads:'https://lead.api.crm.pathkindlabs.com',
        _booking:"https://booking.api.crm.pathkindlabs.com",
        _payment:"https://payment.api.crm.pathkindlabs.com",
        _inbound:'https://inbound.api.crm.pathkindlabs.com',
        _leadHistory:'https://lead.api.crm.pathkindlabs.com',
        _patientInfo:'https://utility.api.crm.pathkindlabs.com',
        _ameyo: 'https://communication.api.crm.pathkindlabs.com',
        _lims_utility: 'https://utility.api.crm.pathkindlabs.com',
        _socket: 'https://prod-crm-websocket.azurewebsites.net'
     },
    apiVer:'/api',
    localJson:'assets/localJson.json',
    webSocket:"https://prod-crm-websocket.azurewebsites.net",
    baseFolder:"",
    utilityAppUrl:{lims_utility:'https://crm.pathkindlabs.com'}
    }
};
